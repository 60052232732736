import { BarChart, BookmarkAdd, Build, CalendarMonth, CalendarToday, Cottage, Dashboard, EventAvailable, FactCheck, Link, Logout, Notes, Person, RateReview, Topic } from '@mui/icons-material';

export const navItems = [
    {
        value: 'Home',
        route: '',
        icon: <Cottage />
    },
    {
        value: 'Transient Board',
        route: 'transient-board',
        icon: <Dashboard />
    },
    {
        value: 'Add Fact',
        route: 'add',
        icon: <Notes />
    },
    {
        value: 'View Facts',
        route: 'get',
        icon: <FactCheck />
    },
    {
        value: 'Build Facts',
        route: 'build',
        icon: <Build />
    },
    {
        value: 'Review Facts',
        route: 'review',
        icon: <RateReview />
    },
    {
        value: 'Add Date Facts',
        route: 'timed',
        icon: <CalendarMonth />
    },
    {
        value: 'Add Personality Facts',
        route: 'personality',
        icon: <Person />
    },
    {
        value: 'Year Facts',
        route: 'yearfacts',
        icon: <CalendarMonth />
    },
    {
        value: 'On This Day',
        route: 'get-timed-facts',
        icon: <CalendarToday />
    },
    {
        value: 'Progress',
        route: 'progress',
        icon: <BarChart />
    },
    {
        value: 'Calendar',
        route: 'calendar',
        icon: <EventAvailable />
    },
];

export const secondaryItems = [
    {
        value: 'Links',
        route: 'links',
        icon: <Link />
    },
    {
        value: 'Post Quiz',
        route: 'postquiz',
        icon: <BookmarkAdd />
    },
    {
        value: 'Story Quiz',
        route: 'storyquiz',
        icon: <BookmarkAdd />
    },
    {
        value: 'All PDFs',
        route: 'all-pdfs',
        icon: <BookmarkAdd />
    },
    {
        value: 'Request Logs',
        route: 'request-logs',
        icon: <BookmarkAdd />
    },
    {
        value: 'Topic Count',
        route: 'topic-count',
        icon: <Topic />
    },
];


export const logoutItem = {
    value: 'Logout',
    route: 'login',
    icon: <Logout />
};
