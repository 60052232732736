import './TimedFacts.css'
import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// For Alert Stacks
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';

import { Autocomplete, IconButton, Input, Table, TableBody, TextField } from "@mui/material";
import { initialTagStates } from '../../../Utils/tags';
import { getLiveEndpoint } from '../../../Utils/url';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Done } from '@mui/icons-material';
import DrawerNav from '../../Navigation/DrawerNav';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const getMonthName = (monthIndex) => {
    let monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return monthsArray[monthIndex];
}

const TimedFacts = () => {

    const [fact, setFact] = useState('')

    const [currentDay, setCurrentDay] = useState(new Date().getDate())
    const [currentMonth, setCurrentMonth] = useState(getMonthName(new Date().getMonth()))
    const [currentMonthNumber, setCurrentMonthNumber] = useState(new Date().getMonth() + 1)
    const [currentYear, setCurrentYear] = useState(2023)

    const [successMessage, setSuccessMessage] = useState('Success!!!')
    const [errorMessage, setErrorMessage] = useState('Error!!!')

    const [state, setState] = React.useState(initialTagStates);

    const [apiCalled, setApiCalled] = useState(false);
    const [blockedDomains, setBlockedDomains] = useState([])

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const clearInputFields = () => {
        setFact('')
        setCurrentDay(new Date().getDate())
        setCurrentMonth(getMonthName(new Date().getMonth()))
        setCurrentYear(2023)
    }

    const getFactsForMinifying = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(getLiveEndpoint(true, 'api/upsc/dated/facts'), requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    setApiCalled(true);
                    const array = data.data.facts
                    setBlockedDomains(array);
                } else if (data.status === 401) {
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const minifiedFactHandler = (factID) => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "factId": factID })
        };

        fetch(getLiveEndpoint(true, 'api/upsc/dated/facts'), requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('Fact has been dated!!')
                    setOpenSuccessAlert(true)
                    getFactsForMinifying()
                    setBlockedDomains((blockedDomains) => {
                        return blockedDomains.filter((product) => product._id !== factID)
                    })
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    setErrorMessage(data.data.error || 'Error')
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    const addTimedFactHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "day": currentDay,
                "month": currentMonthNumber,
                "year": currentYear,
                "fact": fact
            })
        };

        fetch(getLiveEndpoint(true, 'api/upsc/datedfacts'), requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage(data.data.info)
                    setOpenSuccessAlert(true)
                    clearInputFields()
                } else {
                    setErrorMessage(data.data.error)
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            getFactsForMinifying()
        }
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />

            <Box sx={{ flexGrow: 2 }}>
                <h1 className="header-top my-5">Date wise Facts</h1>
                <Grid align="center" justify="center" spacing={2}>
                    <Table sx={{ width: "80%" }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Fact</StyledTableCell>
                                <StyledTableCell>Done</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {apiCalled && blockedDomains.map((eachDomain) => {
                            return (
                                <TableBody>
                                    <StyledTableRow key={eachDomain._id}>
                                        <StyledTableCell component="th" scope="row">
                                            {eachDomain.fact}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <IconButton aria-label="delete" onClick={() => minifiedFactHandler(eachDomain._id)} sx={{ marginX: 1 }}>
                                                <Done style={{ fontSize: 30, color: "#2874A6" }} />
                                            </IconButton>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            )
                        })}
                    </Table>
                </Grid>
                <Grid align="center" justify="center" spacing={2}>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">

                        <Autocomplete
                            disableportal="true"
                            id="combo-box-demo"
                            options={currentDayList}
                            value={currentDay}
                            sx={{ width: "40%", margin: '1%' }}
                            onChange={(event, item) => {
                                if (item === null) return
                                setCurrentDay(item.label)
                            }}
                            renderInput={(params) => <TextField {...params} label="Choose day" />}
                        />

                        <Autocomplete
                            disableportal="true"
                            id="combo-box-demo"
                            options={currentMonthList}
                            value={currentMonth}
                            sx={{ width: "40%", margin: '1%' }}
                            onChange={(event, item) => {
                                if (item === null) return
                                setCurrentMonth(item.label)
                                setCurrentMonthNumber(item.value)
                            }}
                            renderInput={(params) => <TextField {...params} label="Choose day" />}
                        />

                        <Input
                            disablePortal
                            placeholder="Enter Year fact..."
                            id="combo-box-demo"
                            multiline
                            value={currentYear}
                            sx={{ width: "40%", margin: "1%" }}
                            onChange={(event, item) => {
                                setCurrentYear(event.target.value)
                            }}
                        />
                    </Grid>

                    <Grid>
                        <Input
                            disablePortal
                            placeholder="Enter fact..."
                            id="combo-box-demo"
                            multiline
                            value={fact}
                            sx={{ width: "40%", margin: "1%" }}
                            onChange={(event, item) => {
                                setFact(event.target.value)
                            }}
                        />
                    </Grid>

                    <Grid>
                        <Button
                            className="h-12"
                            sx={{ width: "40%", margin: "1%" }}
                            onClick={addTimedFactHandler}
                            color="success"
                            variant="contained">
                            Add timed Fact
                        </Button>
                    </Grid>
                </Grid>


                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
            </Box >
        </Box>
    );
};

const currentDayList = [
    {
        label: 1
    },
    {
        label: 2
    },
    {
        label: 3
    },
    {
        label: 4
    },
    {
        label: 5
    },
    {
        label: 6
    },
    {
        label: 7
    },
    {
        label: 8
    },
    {
        label: 9
    },
    {
        label: 10
    },
    {
        label: 11
    },
    {
        label: 12
    },
    {
        label: 13
    },
    {
        label: 14
    },
    {
        label: 15
    },
    {
        label: 16
    },
    {
        label: 17
    },
    {
        label: 18
    },
    {
        label: 19
    },
    {
        label: 20
    },
    {
        label: 21
    },
    {
        label: 22
    },
    {
        label: 23
    },
    {
        label: 24
    },
    {
        label: 25
    },
    {
        label: 26
    },
    {
        label: 27
    },
    {
        label: 28
    },
    {
        label: 29
    },
    {
        label: 30
    },
    {
        label: 31
    }
]

const currentMonthList = [
    {
        label: 'January',
        value: 1
    },
    {
        label: 'February',
        value: 2
    },
    {
        label: 'March',
        value: 3
    },
    {
        label: 'April',
        value: 4
    },
    {
        label: 'May',
        value: 5
    },
    {
        label: 'June',
        value: 6
    },
    {
        label: 'July',
        value: 7
    },
    {
        label: 'August',
        value: 8
    },
    {
        label: 'September',
        value: 9
    },
    {
        label: 'October',
        value: 10
    },
    {
        label: 'November',
        value: 11
    },
    {
        label: 'December',
        value: 12
    }
]

export default TimedFacts;
