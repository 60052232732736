import './GetMiniFacts.css'

import * as React from 'react';
import { useEffect } from 'react';
import { Grid, IconButton, Snackbar, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CopyIcon from '@mui/icons-material/CopyAll'
import { DoneOutlined } from '@mui/icons-material';
import { getLiveEndpoint } from '../../../Utils/url';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GetMiniFacts = () => {

    const [apiCalled, setApiCalled] = useState(false);
    const [blockedDomains, setBlockedDomains] = useState([])

    const [scheduledPostId, setScheduledPostId] = useState('')

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const resetAllFields = () => {
        setApiCalled(false)
    }

    const copyFactHandler = (text) => {
        navigator.clipboard.writeText(text);
        setOpenSuccessAlert(true);
        setSuccessMessage(`${text} - has been copied to clipboard.`)
    }

    const getBlockedListOfUser = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(getLiveEndpoint(true, 'api/upsc/minifacts'), requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    setApiCalled(true);
                    const array = data.data.facts
                    setBlockedDomains(array);
                } else if (data.status === 401) {
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const completeTodoHandler = (factID) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "factId": factID })
        };

        fetch('https://api.studencity.in/api/upsc/minifacts/complete', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('Todo has been completed!!')
                    setOpenSuccessAlert(true)
                    getBlockedListOfUser()
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    setErrorMessage(data.data.error || 'Error')
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })

        setBlockedDomains((blockedDomains) => {
            return blockedDomains.filter((product) => product._id !== scheduledPostId)
        })
    }

    useEffect(() => {
        if (!apiCalled) {
            getBlockedListOfUser()
        }
    }, [])

    return (
        <Box sx={{ flexGrow: 2 }}>
            <Grid align="center" justify="center" spacing={2}>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <h1 className="header-top mt-5"> Minifacts</h1>
                </Grid>

                {apiCalled === true ?
                    <>
                        <Table sx={{ width: "55%", marginTop: 5, marginBottom: 30 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Fact</StyledTableCell>
                                    <StyledTableCell>Answer</StyledTableCell>
                                    <StyledTableCell>Copy</StyledTableCell>
                                    <StyledTableCell align="left">Done</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {blockedDomains.map((eachDomain) => {
                                return (
                                    <TableBody>
                                        <StyledTableRow key={eachDomain._id}>
                                            <StyledTableCell component="th" scope="row">
                                                {eachDomain.fact}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                {eachDomain.answer}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                <IconButton aria-label="delete" onClick={() => copyFactHandler(eachDomain.fact)}>
                                                    <CopyIcon style={{ fontSize: 30, color: "#0F52BA" }} />
                                                </IconButton>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                <IconButton aria-label="delete" onClick={() => completeTodoHandler(eachDomain._id)}>
                                                    <DoneOutlined className='instaIcon' style={{ fontSize: 30, fontWeight: 600 }} />
                                                </IconButton>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                )
                            })}
                        </Table>
                    </> : ''}
            </Grid>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </Box>
    )
}

export default GetMiniFacts;