import './Stats.css'
import React, { useEffect, useState } from "react";
import { getLiveEndpoint } from '../../../Utils/url';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? 'white' : '#308fe8',
    },
}));

const Stats = () => {

    const [apiCalled, setApiCalled] = useState(false)
    const [stats, setStats] = useState({})

    const navigate = useNavigate()

    const getDashboardStats = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(getLiveEndpoint(true, 'api/upsc/dashboard/stats'), requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    setApiCalled(true);
                    setStats(data.data.stats)
                } else if (data.status === 401 || data.status === 400) {
                } else if (data.status === 403) {
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const routeHandler = (route) => {
        navigate(route)
    }

    useEffect(() => {
        if (!apiCalled) {
            getDashboardStats()
        }
    }, [])

    const totalDaysOfDashboard = () => {
        const days = (new Date().getTime() - 1688149800000) / 86400000
        return Math.floor(days)
    }

    const getDailyAverage = (total) => {
        const dashboardStartDate = 1688149800000
        const daysTillToday = Math.round(
            (new Date().getTime() - dashboardStartDate) / 86400000
        )
        return (total / daysTillToday).toFixed(2)
    }

    const levels = [500, 1000, 2000, 5000, 10000, 20000, 50000, 100000]
    const getNextLevel = (value) => {
        for (let i = 0; i < levels.length; i++) {
            if (levels[i] > value) {
                return levels[i];
            }
        }
    }

    return (
        <>
            {apiCalled === true ?
                <>
                    <div className='header__div__stats py-5'>
                        <div class="container mx-auto px-6">
                            <h2 class="text-6xl font-bold my-5 text-white">
                                Daily average - {stats.dailyAverage} <span className='text-2xl font-normal'>over {totalDaysOfDashboard()} days</span>
                            </h2>
                        </div>
                    </div>
                    <div className='header__div__stats py-2'>
                        <div class="container mx-auto px-6">
                            <h3 class="text-2xl mb-4 text-gray-200">
                                To reach <span className='font-bold text-4xl'>{getDailyAverage(stats.total + 10)}</span>, add <span className='font-bold text-4xl'>10</span> facts today
                            </h3>
                            <h3 class="text-2xl mb-4 text-gray-200">
                                To reach <span className='font-bold text-4xl'>{getDailyAverage(stats.total + 20)}</span>, add <span className='font-bold text-4xl'>20</span> facts today
                            </h3>
                            <h3 class="text-2xl mb-4 text-gray-200">
                                To reach <span className='font-bold text-4xl'>{getDailyAverage(stats.total + 30)}</span>, add <span className='font-bold text-4xl'>30</span> facts today
                            </h3>
                            <h3 class="text-2xl mb-4 text-gray-200">
                                To reach <span className='font-bold text-4xl'>{getDailyAverage(stats.total + 50)}</span>, add <span className='font-bold text-4xl'>50</span> facts today
                            </h3>
                            <h3 class="text-2xl mb-4 text-gray-200">
                                To reach <span className='font-bold text-4xl'>{getDailyAverage(stats.total + 100)}</span>, add <span className='font-bold text-4xl'>100</span> facts today
                            </h3>
                        </div>
                    </div>
                    <div className='header__div__stats__2 py-10'>
                        <div class="container mx-auto px-6">
                            <h2 class="text-4xl font-bold mb-2 text-white">
                                You don't have to see the whole staircase, just take the first step.
                            </h2>
                            <h3 class="text-2xl my-4 text-gray-200">
                                You have added <span className='text-4xl'>{stats.total}</span> of <span className='text-4xl'>{getNextLevel(stats.total)} </span> facts.
                            </h3>
                            <BorderLinearProgress variant="determinate" value={(stats.total / getNextLevel(stats.total)) * 100} className='mt-10' />
                        </div>
                    </div>
                    <div className='header__div__stats py-10'>
                        <div class="container mx-auto px-6">
                            <h2 class="text-4xl font-bold mb-2 text-white">
                                Personal growth is about progress, not perfection
                            </h2>
                            <h3 class="text-2xl mb-8 text-gray-200">
                                Monitor your growth here.
                            </h3>

                            <button class="bg-white font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider" onClick={() => routeHandler('/transient')}>
                                Add More Facts
                            </button>
                        </div>
                    </div>
                    <div className='header__div__stats__2 py-10'>
                        <div class="container mx-auto px-6">
                            <h2 class="text-4xl font-bold mb-2 text-white">
                                Excellence is the gradual result of always striving to do better
                            </h2>
                            <h3 class="text-2xl my-4 text-gray-200">
                                You have accumulated {stats.accumulated} of 500 facts
                            </h3>
                            <BorderLinearProgress variant="determinate" value={(stats.accumulated / 500) * 100} className='my-10' />
                            <button class="bg-white font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider" onClick={() => routeHandler('/transient')}>
                                Accumulate More
                            </button>
                        </div>
                    </div>
                    <div className='header__div__stats py-10'>
                        <div class="container mx-auto px-6">
                            <h2 class="text-4xl font-bold my-5 pb-5 text-white">
                                Build Facts - {stats.buildCount}
                            </h2>
                            <button class="bg-white font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider" onClick={() => routeHandler('/build')}>
                                Build Now
                            </button>
                        </div>
                    </div>
                </> : <></>}
        </>
    )
}

export default Stats;