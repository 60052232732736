import './Calendar.css'

import * as React from 'react';
import { useEffect } from 'react';
import { Grid, IconButton, Snackbar, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import MuiAlert from '@mui/material/Alert';
import { FiberManualRecord, Replay } from '@mui/icons-material';
import DrawerNav from '../../Navigation/DrawerNav';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Calendar = () => {

    const [apiCalled, setApiCalled] = useState(false);
    const [blockedDomains, setBlockedDomains] = useState([])

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const resetAllFields = () => {
        // setApiCalled(false)
    }

    const getBlockedListOfUser = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch('https://api.studencity.in/api/upsc/dashboard/calendar/schedule', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    // setApiCalled(true);
                    const array = data.data.calendar
                    setBlockedDomains(array);
                    setSuccessMessage('Calendar has been fetched!!')
                    setOpenSuccessAlert(true)
                } else if (data.status === 401) {
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    useEffect(() => {
        setInterval(getBlockedListOfUser, 8000);
    }, [])


    const getMonthNameFromNumber = (monthIndex) => {
        let monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return monthsArray[monthIndex - 1];
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box sx={{ flexGrow: 2 }}>
                    <Grid align="center" justify="center" spacing={2}>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <h1 className="text-4xl mt-5">
                                <IconButton aria-label="delete" onClick={getBlockedListOfUser} >
                                    <Replay style={{ fontSize: 40, marginTop: "-5px" }} className='reloadPage' />
                                </IconButton>
                                Scheduled Posts Calendar
                            </h1>

                        </Grid>

                        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ px: "5%", marginTop: "10px", marginBottom: "30px" }} >
                            {blockedDomains.map((eachDomain) => {
                                return (
                                    <Grid item lg={2.4} md={6} sm={12} xs={12}>
                                        <div class="flex flex-row content-center justify-center	m-auto p-4 gap-8 rounded-lg border-2 border-gray-500">
                                            <div class="my-auto">
                                                <div class="text-2xl">{`${eachDomain.day} ${getMonthNameFromNumber(eachDomain.month)}`}</div>
                                                {
                                                    [...Array(eachDomain.uploadedCount)].map(() => {
                                                        return (
                                                            <IconButton aria-label="delete" sx={{}}>
                                                                <FiberManualRecord style={{ fontSize: 25, color: "#50C878" }} />
                                                            </IconButton>
                                                        )
                                                    })
                                                }
                                                {
                                                    [...Array(eachDomain.buildCount)].map(() => {
                                                        return (
                                                            <IconButton aria-label="delete" sx={{}}>
                                                                <FiberManualRecord style={{ fontSize: 25, color: "crimson" }} />
                                                            </IconButton>
                                                        )
                                                    })
                                                }
                                                {
                                                    [...Array(eachDomain.reviewCount)].map(() => {
                                                        return (
                                                            <IconButton aria-label="delete" sx={{}}>
                                                                <FiberManualRecord style={{ fontSize: 25, color: "#FF7F50" }} />
                                                            </IconButton>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })
                            }
                        </Grid>
                    </Grid>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                            <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                                {successMessage}
                            </Alert>
                        </Snackbar>
                    </Stack>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                            <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                                {errorMessage}
                            </Alert>
                        </Snackbar>
                    </Stack>
                </Box>
            </Box>
        </>

    )
}

export default Calendar;