exports.topicsList = [
    {
        label: 'Geography',
        value: 'geography'
    },
    {
        label: 'History',
        value: 'history'
    },
    {
        label: 'Economy',
        value: 'economy'
    },
    {
        label: 'Polity',
        value: 'polity'
    },
    {
        label: 'Science',
        value: 'science'
    },
    {
        label: 'National',
        value: 'national'
    },
    {
        label: 'International',
        value: 'international'
    },
    {
        label: 'Defence',
        value: 'defence'
    },
    {
        label: 'Sports',
        value: 'sports'
    },
    {
        label: 'GK',
        value: 'gk'
    }
]

const georgraphySubtopics = [
    { label: 'The Earth and Universe' },
    { label: 'Physiography and Locations' },
    { label: 'Earth Atmosphere' },
    { label: 'Drainage System' },
    { label: 'Mountains and Peaks' },
    { label: 'Wildlife Conservation' },
    { label: 'Water Resources' },
    { label: 'Tourism Sector' },
    { label: 'Himalayas' },
    { label: 'National Parks and Wildlife Sanctuaries' },
    { label: 'Climate of the World' },
    { label: 'Deserts and Mangroves' },
    { label: 'Longitudes and Latitudes' },
    { label: 'Ocean and Ocean Currents' },
    { label: 'Agriculture Sector' },
    { label: 'Climate Change' },
    { label: 'Natural Disasters' },
    { label: 'Port and Seas' },
    { label: 'Lakes and Glaciers' },
    { label: 'States and UTs' },
    { label: 'Tribal Areas' },
    { label: 'Minerals' },
    { label: 'Peninsula' },
    { label: 'Volcanoes' },
    { label: 'World Population' },
    { label: 'Islands and Straits' },
    { label: 'Rivers and Climate' },
    { label: 'Natural Vegetation' },
    { label: 'Transportation Sector of India' },
    { label: 'Manufacturing Industries' },
    { label: 'Mineral and Energy Resources' },
    { label: 'Heritage Sites and UNESCO' },
    { label: 'Geographical Boundaries' },
    { label: 'Wetlands' },
    { label: 'Geographical Indication' },
    { label: 'Sustainable Development' },
    { label: 'Flora and Fauna' },
    { label: 'Miscellaneous' }
]

const historySubtopics = [
    { label: 'Harappa Civilization' },
    { label: 'The Vedic Era' },
    { label: 'Archaeology' },
    { label: 'Indian Freedom Struggle'},
    { label: 'Freedom Fighters' },
    { label: 'Mauryan Period' },
    { label: 'Battles and Wars' },
    { label: 'Gupta Period' },
    { label: 'Early Medieval Period' },
    { label: 'Indian National Army' },
    { label: 'The Delhi Sultanate' },
    { label: 'Religious Movements' },
    { label: 'Chola, Chera and Pandyas' },
    { label: 'Foreign Travellers' },
    { label: 'The Mughal Empire' },
    { label: 'The Maratha Kingdom' },
    { label: 'The Sikhs' },
    { label: 'Portuguese Empire' },
    { label: 'Europeans in India' },
    { label: 'Buddhism and Jainism' },
    { label: 'British Rule in India' },
    { label: 'Partition and Independence' },
    { label: 'Indian National Congress' },
    { label: 'Art and Culture' },
    { label: 'Indian National Movement' },
    { label: 'Viceroy and Governor-Generals' },
    { label: 'World History' },
    { label: 'Historical Literature' },
    { label: 'Pala' }
]

const economySubtopics = [
    { label: 'Planning' },
    { label: 'Industrial Sector' },
    { label: 'Indian Railways' },
    { label: 'Indian Fiscal System' },
    { label: 'Banking Sector' },
    { label: 'Poverty and Employment' },
    { label: 'Human Development' },
    { label: 'Taxation in India' },
    { label: 'Investments' },
    { label: 'Payment System' },
    { label: 'Indian Trade' },
    { label: 'Illegal Trade'},
    { label: 'Communication Sector' },
    { label: 'Census' }
]

const politySubtopics = [
    { label: 'History of Constitution' },
    { label: 'Parliament of India' },
    { label: 'The Constitution of India' },
    { label: 'Public Administration' },
    { label: 'Fundamental Rights and Duties' },
    { label: 'The Union Executive' },
    { label: 'The Union Legislative' },
    { label: 'The Judiciary' },
    { label: 'The State Legislature' },
    { label: 'Relation between State and Union' },
    { label: 'Constitutional Bodies' },
    { label: 'Panchayats and Municipalies' },
    { label: 'Local Self Government' },
    { label: 'Constitutional Articles and Amendments' },
    { label: 'Government Schemes' },
    { label: 'Education System' },
    { label: 'Election and Democracy' },
    { label: 'Acts and Policies' },
    { label: 'Press and News' }
]

const scienceSubtopics = [
    { label: 'Physics' },
    { label: 'Chemistry' },
    { label: 'Biology' },
    { label: 'Inventions and Discoveries' },
    { label: 'Space Research' },
    { label: 'Famous Scientists' },
    { label: 'Science Organisations' }
]

const defenceSubtopics = [
    { label: 'Defence Exercises' },
    { label: 'Indian Army' },
    { label: 'Indian Navy' },
    { label: 'Indian Air Force' },
    { label: 'Defence Agreements'},
    { label: 'National Security' },
    { label: 'Missiles and Aircrafts' },
    { label: 'Defence Organisations' }
]

const sportsSubtopics = [
    { label: 'Sports Awards' },
    { label: 'Sports Personalities' },
    { label: 'Trophies and Cups' },
    { label: 'Olympics' },
    { label: 'Sports Bodies'},
    { label: 'Asian and Commonwealth Games' },
    { label: 'Physical Fitness and Yoga' },
    { label: 'Sports Initiatives' },
    { label: 'Sports in India' }
]

const gkSubtopics = [
    { label: 'Days and Events' },
    { label: 'Reports and Indices' },
    { label: 'Awards and Honours' },
    { label: 'Personalities' },
    { label: 'Books and Authors'},
    { label: 'Information Technology'},
    { label: 'Miscellaneous'}
]

const internationalSubtopics = [
    { label: 'Treaties and Conventions' },
    { label: 'Group of Countries' },
    { label: 'United Nations' },
    { label: 'European Union' },
    { label: 'International Organisations' },
    { label: 'African Countries' }
]

const nationalSubtopics = [{ label: 'National Organisations' }]

exports.subtopicsMap = {
    geography: georgraphySubtopics,
    history: historySubtopics,
    national: nationalSubtopics,
    economy: economySubtopics,
    international: internationalSubtopics,
    polity: politySubtopics,
    science: scienceSubtopics,
    defence: defenceSubtopics,
    sports: sportsSubtopics,
    gk: gkSubtopics
}
