import { Autocomplete, Box, Button, Grid, Snackbar, Stack, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import './PDFAll.css'
import { imageData } from './image';
import { jsPDF } from "jspdf";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const getMonthName = (monthIndex) => {
    let monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return monthsArray[monthIndex];
}

const getMonthNumberFromMonthName = (month) => {
    console.log('Choosen month : ', month)
    if (month === 'July') return 7
    else if (month === 'August') return 8
    else if (month === 'September') return 9
    else if (month === 'October') return 10
    return 11
}

function PDFAll() {
    const [currentMonthNumber, setCurrentMonthNumber] = useState(new Date().getMonth() + 1)
    const [currentmonth, setCurrentMonth] = useState(getMonthName(new Date().getMonth()))
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

    // type -> monthly, weekly, quizset, topicwise

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const getMonthlyFacts = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "month": currentMonthNumber, "year": currentYear })
        };

        fetch('https://api.studencity.in/api/upsc/facts/monthly', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('PDF downloaded!!!')
                    setOpenSuccessAlert(true)
                    // setFactData()
                    // downloadPDFMonthlyOptimized(data.data.list)
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const getWeeklyFacts = (WEEK_NO) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "month": currentMonthNumber, "year": currentYear, "week": WEEK_NO })
        };

        fetch('https://api.studencity.in/api/upsc/facts/weekly', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('PDF downloaded!!!')
                    setOpenSuccessAlert(true)
                    // setFactData()
                    // downloadPDFMonthlyOptimized(data.data.list)
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const getQuizSetFacts = (QUIZ_SET_NO) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "month": currentMonthNumber, "year": currentYear, "setId": QUIZ_SET_NO })
        };

        fetch('https://api.studencity.in/api/upsc/pdf/quizset', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('PDF downloaded!!!')
                    setOpenSuccessAlert(true)
                    // setFactData()
                    downloadPDFQuizSetOptimized(data.data.list, QUIZ_SET_NO)
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const setWeeklyPDFHeaderAndFooter = (doc, heading) => {
        // Header
        var xOffset = doc.internal.pageSize.width / 2
        doc.setFont("Calibri", "bold")
        doc.setFontSize(36);
        doc.setTextColor("#0096FF");
        doc.text(heading, xOffset, 35, 'center');
        doc.setTextColor("#373737");
        doc.setFontSize(24)
        doc.text(`${currentmonth}, ${currentYear}`, xOffset, 60, {
            align: "center"
        });
        doc.setFontSize(16);
        doc.setTextColor("black");
        doc.setFont("Calibri", "normal")

        // Footer
        doc.addImage(imageData, 'jpeg', xOffset - 10, doc.internal.pageSize.height - 50, 30, 30, 'center');
    }

    const setMainPageOfPdf = (doc, set_no) => {
        var xOffset = doc.internal.pageSize.width / 2
        doc.setFont("Trebuchet MS", "bold");
        doc.setFontSize(100);
        doc.setTextColor("#0096FF");
        doc.text("GK QUIZ", xOffset, 250, 'center');
        doc.setTextColor("#373737");
        doc.setFontSize(80)
        doc.text(`${currentmonth}, ${currentYear}`, xOffset, 320, {
            align: "center"
        });
        doc.setTextColor("#373737");
        doc.setFontSize(60)
        doc.text(`SET - ${set_no}`, xOffset, 400, 'center');

        doc.setFontSize(16);
        doc.setTextColor("black");
        doc.setFont("Calibri", "normal")

        // Footer
        doc.addImage(imageData, 'jpeg', xOffset - 10, doc.internal.pageSize.height - 100, 50, 50, 'center');
        doc.addPage()
    }

    const downloadPDFQuizSetOptimized = (FACT_DATA, QUIZ_SET_NO) => {
        const sideLength = 600
        const doc = new jsPDF('p', 'px', [sideLength, sideLength])
        doc.setFont('Roboto');
        setMainPageOfPdf(doc, QUIZ_SET_NO)

        // -----------------------------------------------------------------------------------------------------------
        // -------------------------------------      FACTS        ---------------------------------------------------
        // -----------------------------------------------------------------------------------------------------------
        let spacingBetweenFacts = 90
        let combinedFact = ''
        let factLimit = 6
        let combinedFactArray = []

        // -----------------------------------------------------------------------------------------------------------
        // -------------------------------------      QUESTIONS        -----------------------------------------------
        // -----------------------------------------------------------------------------------------------------------

        setWeeklyPDFHeaderAndFooter(doc, `GK Quiz Set - ${QUIZ_SET_NO}`)
        let questions = FACT_DATA.questions
        spacingBetweenFacts = 90
        combinedFact = ''
        factLimit = 10
        combinedFactArray = []
        questions.forEach((question, i) => {
            combinedFact += (`${i + 1}. ` + question + '\n\n')
            factLimit--
            if (combinedFact.length < 1300 && factLimit === 0) {
                factLimit++
            } else if (factLimit === 0) {
                combinedFactArray.push(combinedFact)
                combinedFact = ''
                factLimit = 10
            }
        })

        if (combinedFact.length > 0) {
            combinedFactArray.push(combinedFact)
        }

        combinedFactArray.forEach((nFacts, i) => {
            setWeeklyPDFHeaderAndFooter(doc, `GK Quiz Set - ${QUIZ_SET_NO}`)
            doc.text(50, spacingBetweenFacts, nFacts, { maxWidth: 500 })
            if (i < combinedFactArray.length - 1) {
                doc.addPage()
            }
        })

        doc.addPage()

        // -----------------------------------------------------------------------------------------------------------
        // -------------------------------------      ANSWERS        -------------------------------------------------
        // -----------------------------------------------------------------------------------------------------------

        setWeeklyPDFHeaderAndFooter(doc, `Answers : GK Set - ${QUIZ_SET_NO}`)
        let answers = FACT_DATA.answers
        spacingBetweenFacts = 90
        combinedFact = ''
        factLimit = 10
        combinedFactArray = []
        answers.forEach((question, i) => {
            combinedFact += (`${i + 1}. ` + question + '\n\n')
            factLimit--
            if (combinedFact.length < 300 && factLimit === 0) {
                factLimit++
            } else if (factLimit === 0) {
                combinedFactArray.push(combinedFact)
                combinedFact = ''
                factLimit = 10
            }
        })

        if (combinedFact.length > 0) {
            combinedFactArray.push(combinedFact)
        }

        combinedFactArray.forEach((nFacts, i) => {
            setWeeklyPDFHeaderAndFooter(doc, `Answers : GK Set - ${QUIZ_SET_NO}`)
            doc.text(50, spacingBetweenFacts, nFacts, { maxWidth: 500 })
            if (i < combinedFactArray.length - 1) {
                doc.addPage()
            }
        })

        doc.addPage()

        // ____________________________________________________________________________________________
        //_________________________________EXPLANATION_________________________________________________
        // ____________________________________________________________________________________________
        let facts = FACT_DATA.facts
        spacingBetweenFacts = 90
        combinedFact = ''
        factLimit = 6
        combinedFactArray = []
        setWeeklyPDFHeaderAndFooter(doc, `Explanation : GK Set - ${QUIZ_SET_NO}`)
        facts.forEach((fact, i) => {
            combinedFact += (`${i + 1}. ` + fact + '\n\n')
            factLimit--
            if (combinedFact.length < 1700 && factLimit === 0) {
                factLimit++
            } else if (factLimit === 0) {
                combinedFactArray.push(combinedFact)
                combinedFact = ''
                factLimit = 6
            }
        })

        if (combinedFact.length > 0) {
            combinedFactArray.push(combinedFact)
        }

        combinedFactArray.forEach((nFacts, i) => {
            setWeeklyPDFHeaderAndFooter(doc, `Explanation : GK Set - ${QUIZ_SET_NO}`)
            doc.text(50, spacingBetweenFacts, nFacts, { maxWidth: 500 })
            if (i < combinedFactArray.length - 1) {
                doc.addPage()
            }
        })

        doc.save(`Quiz-Set-${QUIZ_SET_NO}-${currentmonth}-${currentYear}`)
    }

    const getTopicwiseFacts = (topicsList) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "month": currentMonthNumber, "year": currentYear, "topicsList": topicsList })
        };

        fetch('https://api.studencity.in/api/upsc/facts/topicwise', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('PDF downloaded!!!')
                    setOpenSuccessAlert(true)
                    // setFactData()
                    // downloadPDFMonthlyOptimized(data.data.list)
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const downloadHandler = (type, payload) => {
        console.log('Type : ', type, ', Payload : ', payload)
        if (type === 'monthly') {
            getMonthlyFacts()
        } else if (type === 'weekly') {
            getWeeklyFacts(payload)
        } else if (type === 'quizset') {
            getQuizSetFacts(Number(payload))
        } else if (type === 'topicise') {
            getTopicwiseFacts(payload)
        } else {
            console.log('Should not be here. Handle this case.')
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <div className='pdf__container'>
            <Box sx={{ flexGrow: 2 }}>
                <Grid align="center" justify="center" spacing={2}>

                    <Grid item lg={4} md={6} sm={12} className="py-2">
                        <h1 className="header-top"> {currentmonth + ',' + currentYear}</h1>
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={currentmonth}
                            options={monthsList}
                            className='eachInputBox'
                            sx={{}}
                            onChange={(event, item) => {
                                setCurrentMonth(item.label);
                                setCurrentMonthNumber(getMonthNumberFromMonthName(item.label))
                            }}
                            renderInput={(params) => <TextField {...params} label="Choose month" />}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={currentYear}
                            options={yearList}
                            className='eachInputBox'
                            sx={{}}
                            onChange={(event, item) => {
                                setCurrentYear(item.label)
                            }}
                            renderInput={(params) => <TextField {...params} label="Choose year" />}
                        />
                    </Grid>
                </Grid>
            </Box>
            <div className='text-2xl mt-5'>Monthly PDFs</div>
            <div className='each__pdf__container'>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('monthly')}>
                    Monthly PDF
                </Button>
            </div>
            <div className='text-2xl mt-5'>Weekly PDFs</div>
            <div className='each__pdf__container'>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('weekly', 1)}>
                    Week 1
                </Button>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('weekly', 2)}>
                    Week 2
                </Button>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('weekly', 3)}>
                    Week 3
                </Button>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('weekly', 4)}>
                    Week 4
                </Button>
            </div>
            <div className='text-2xl mt-5'>GK Quiz Sets</div>
            <div className='each__pdf__container'>
                <Button
                    variant="contained"
                    className='each__button'
                    onClick={() => downloadHandler('quizset', 1)}>
                    GK Quiz Set - 1
                </Button>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('quizset', 2)}>
                    GK Quiz Set - 2
                </Button>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('quizset', 3)}>
                    GK Quiz Set - 3
                </Button>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('quizset', 4)}>
                    GK Quiz Set - 4
                </Button>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('quizset', 5)}>
                    GK Quiz Set - 5
                </Button>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('quizset', 6)}>
                    GK Quiz Set - 6
                </Button>
            </div>
            <div className='text-2xl mt-5'>Topicwise</div>
            <div className='each__pdf__container'>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('topicwise', ['National'])}>
                    National
                </Button>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('topicwise', ['International'])}>
                    International
                </Button>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('topicwise', ['Geography'])}>
                    Geography
                </Button>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('topicwise', ['History'])}>
                    History
                </Button>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('topicwise', ['Polity', 'Economy'])}>
                    Polity and Economy
                </Button>
                <Button
                    variant="contained"
                    className='each__button'
                    sx={{}}
                    onClick={() => downloadHandler('topicwise', ['Year'])}>
                    Year
                </Button>
            </div>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </div>
    )
}


const monthsList = [
    {
        label: 'June'
    },
    {
        label: 'July'
    },
    {
        label: 'August'
    },
    {
        label: 'September'
    },
    {
        label: 'October'
    },
    {
        label: 'November'
    },
    {
        label: 'December'
    }
]

const yearList = [
    {
        label: 2023
    },
    {
        label: 2024
    }
]


export default PDFAll