const countries = [
    'Afghanistan',
    'Åland Islands',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bangladesh',
    'Barbados',
    'Bahamas',
    'Bahrain',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'British Indian Ocean Territory',
    'British Virgin Islands',
    'Brunei Darussalam',
    'Bulgaria',
    'Burkina Faso',
    'Burma',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Cayman Islands',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos (Keeling) Islands',
    'Colombia',
    'Comoros',
    'Congo-Brazzaville',
    'Congo-Kinshasa',
    'Cook Islands',
    'Costa Rica',
    'Croatia',
    'Curaçao',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor',
    'Ecuador',
    'El Salvador',
    'Egypt',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Falkland Islands',
    'Faroe Islands',
    'Federated States of Micronesia',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'French Southern Lands',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Heard and McDonald Islands',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macau',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'Northern Mariana Islands',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Pitcairn Islands',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Réunion',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Barthélemy',
    'Saint Helena',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Martin',
    'Saint Pierre and Miquelon',
    'Saint Vincent',
    'Samoa',
    'San Marino',
    'São Tomé and Príncipe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Sint Maarten',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia',
    'South Korea',
    'Spain',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Svalbard and Jan Mayen',
    'Sweden',
    'Swaziland',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Vietnam',
    'Venezuela',
    'Wallis and Futuna',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe'
]

const gkTerms = ['economic']

const defenceTerms = [
    'missile',
    'missiles',
    'defence',
    'navy',
    'army',
    'corvette',
    'submarine'
]

// TODO:: Replace Capital letters
let indianStates = [
    'andhra',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'jammu and Kashmir',
    'jharkhand',
    'Karnataka',
    'Kerala',
    'madhya Pradesh',
    'maharashtra',
    'manipur',
    'meghalaya',
    'mizoram',
    'nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'tamil Nadu',
    'telangana',
    'tripura',
    'Uttarakhand',
    'Uttar Pradesh',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli',
    'Daman and Diu',
    'Delhi',
    'Lakshadweep',
    'Puducherry'
]

const healthWords = [
    'blood',
    'anemia',
    'glucose',
    'diabetes',
    'yoga',
    'exercise',
    'homeopathy'
]

const foodWords = ['rice']

const polityWords = [
    'polity',
    'constitutional',
    'constitution',
    'state',
    "president's",
    'article'
]

const placesWords = ['hampi', 'udupi', 'kumbh']

const organizationWords = [
    'unesco',
    'who',
    'un',
    'unicef',
    'unwto',
    'isro',
    'nasa'
]

const miscellaneousWords = [
    'architecture',
    'dravidian',
    'movement',
    'day',
    'founder'
]

const awardWords = ['nobel', 'prize', 'medal', 'award']

const geographyWords = ['water', 'air']

const countHashtagsAndMentions = (str) => {
    return str.replace(/[^#]/g, '').length
}

exports.genericHashtagGenerator = (sentence) => {
    let result = ''

    const words = sentence.split(' ')

    const addHashtagAfterRepeatCheck = (tag) => {
        tag = tag.toLowerCase()
        if (!result.includes(tag)) {
            result += `#${tag.toLowerCase()} `
        } else {
            console.log(`#${tag} tag is already present.`)
        }
    }

    let countryTag = false
    let gkTag = false
    let defenceTag = false
    let indianStatesTag = false
    let healthTag = false
    let foodTag = false
    let polityTag = false
    let placesTag = false
    let organizationTag = false
    let miscellaneousTag = false
    let awardTag = false
    let geographyTag = false
    let scienceTag = false

    let tagCount = 0

    words.forEach((word) => {
        console.log(word)
        if (countries.includes(word)) {
            addHashtagAfterRepeatCheck(word)
            countryTag = true
            tagCount++
        } else if (gkTerms.includes(word.toLowerCase())) {
            addHashtagAfterRepeatCheck(word)
            gkTag = true
            tagCount++
        } else if (defenceTerms.includes(word.toLowerCase())) {
            addHashtagAfterRepeatCheck(word)
            defenceTag = true
            tagCount++
        } else if (indianStates.includes(word.toLowerCase())) {
            addHashtagAfterRepeatCheck(word)
            indianStatesTag = true
            tagCount++
        } else if (healthWords.includes(word.toLowerCase())) {
            addHashtagAfterRepeatCheck(word)
            healthTag = true
            tagCount++
        } else if (foodWords.includes(word.toLowerCase())) {
            addHashtagAfterRepeatCheck(word)
            foodTag = true
            tagCount++
        } else if (polityWords.includes(word.toLowerCase())) {
            addHashtagAfterRepeatCheck(word)
            polityTag = true
            tagCount++
        } else if (placesWords.includes(word.toLowerCase())) {
            addHashtagAfterRepeatCheck(word)
            placesTag = true
            tagCount++
        } else if (organizationWords.includes(word.toLowerCase())) {
            addHashtagAfterRepeatCheck(word)
            organizationTag = true
            tagCount++
        } else if (miscellaneousWords.includes(word.toLowerCase())) {
            addHashtagAfterRepeatCheck(word)
            miscellaneousTag = true
            tagCount++
        } else if (awardWords.includes(word.toLowerCase())) {
            addHashtagAfterRepeatCheck(word)
            awardTag = true
            tagCount++
        } else if (geographyWords.includes(word.toLowerCase())) {
            addHashtagAfterRepeatCheck(word)
            geographyTag = true
            tagCount++
        }
    })

    console.log('Hashtags used till now : ', countHashtagsAndMentions(result))

    if (countryTag) result += '#indiafacts #geography #sarkarinaukri '
    if (gkTag) result += '#gkfacts #gkquestion #gyanopedia '
    if (defenceTag) result += '#defence #ips #ifs #nda '
    if (indianStatesTag) result += '#ssc #uppcs #bankexam '
    if (healthTag) result += '#happy #health #lifestyle #wellness #healthyfood '
    if (foodTag) result += '#food #nutrition #diet '
    if (polityTag) result += '#president '
    if (placesTag) result += ''
    if (organizationTag) result += ''
    if (miscellaneousTag) result += ''
    if (awardTag) result += ''
    if (geographyTag) result += ''
    if (scienceTag) result += '#generalscience '

    if (tagCount === 0) {
        result += '#upscinfact #currentaffairs #dailyfacts #civilservice '
    } else if (tagCount === 1) {
        result += '#lbsnaa #civilservices #worldfacts #pcs '
    } else if (tagCount === 2) {
        result += '#upscaspirant #ncert #studymotivation #realfacts '
    } else if (tagCount === 3) {
        result += '#upsc #follow #newfacts #gkworld '
    } else if (tagCount === 4) {
        result += '#upscmains #currentaffairs2023 #knowledge #chsl '
    } else if (tagCount === 5) {
        result += '#ibps #sscchsl #currentaffairsquiz #gktoday '
    } else if (tagCount === 6) {
        result += '#gknews #allfacts #gkquiz #gknotes '
    } else if (tagCount === 7) {
        result += '#staticgk #ncert #president #primeminister'
    }

    console.log(result)
    return result
}

// Generates between 15 and 25
function generateRandomNumber() {
    const random = Math.random()
    const randomNumber = 15 + random * 10
    return Math.floor(randomNumber)
}

const hashtagGeneratorHelper = () => {
    const hashtags =
        'upsc,upscmotivation,upscaspirants,studyinspiration,iasmotivationdream,prelims,instagram,science,delhi,news,inspiration,lbsnaa,iasmotivation,studygram,policeofficer,ias,civilservices,iasofficer,officer,gktricks,police,motivation,studylife,study,upscpreparation,upscnotes,upscexam,upsc2023,upscguide,upscprelims,upscmeme,student,upscpathshala,dailycurrentaffairs,upsc2024,upsctopper,upscias,upscaspirant,ssc,studymotivation,ssccgl,nda,india,drdo,bank,railway,ips,irs,ifs,generalknowledge,sarkarinaukri,gkquestion,generalscience,delhipolice,gyanopedia,uppcs,bankexam,upscalesalon,dailyfacts,civilservice,worldfacts,pcs,upscmains,currentfaffairs2023,knowledge,chsl,ibps,sscchsl,currentaffairsquiz,gkworld,gktoday,gknews,hindiknowledge,allfacts,gkquiz,staticgk,ncrt,indianmilitary,ndaexam,nccindia,statepsc,defencelover,statepcs,polity,historygk,indiangk,incredibleindia,ctet,rajasthangkinhindi,indiagktrick,indianforces,biharpolice,monumentsofindia,currentgkindia,heritageofindia,worldgk,currentaffairslatest,indianhistoryandculture,indiahistory,hindigk,patwari,govtjob,competitiveexams,railwayexam,ssccglexam,currentaffairsupdates,currentaffairs2023,upsclover,upscprelims2023,sscexam,gktrick,gktraining,gkquestions,gkhindi,gknotes,generalstudies,govtexam,indiafacts,wifistudy,cds,afcat,geography,csat,indianpolity,aspirants,upscbooks,reasoning,uppsc,sbi,mppsc,bpsc,gkfact,gkfacts,icar,follow,generalknowledgefollow,motivationmilestone,indianarmy,banking,bankpo,rpsc,groupd,exampreparation,clerk,indianconstitution,onlinestudy,sscmts,rrbntpccoaching,currentaffairsinhindi,tnpsccurrentaffairs,current_affairs,currentaffairsnews,currentaffairsdaily,upsccurrentaffairs,currentaffairsinenglish,currentaffairsforupsc,currentaffairspdf,currentaffairsindia,currentaffairsgk,currentaffairsquestions'

    let hashArray = hashtags.split(',')
    // let duplicates = findDuplicates(hashArray);
    // console.log("Duplicates : ", duplicates);
    hashArray = shuffle(hashArray)
    const hashtagCount = generateRandomNumber()
    hashArray = hashArray.slice(0, hashtagCount)

    let result = ''
    hashArray = shuffle(hashArray)
    hashArray.forEach((hashtag) => {
        result += `#${hashtag} `
    })
    return result
}

const paragraphs = [
    "Embark on a journey of enlightenment with our Instagram channel! 🌍🧠 Stay ahead with the latest current affairs, as we unravel intricate details about the world's dynamic events. Our daily posts are a treasure trove of diverse knowledge, serving you with captivating insights on science, history, technology, and more. Join us in expanding your horizons and engaging in intellectual exploration every day! 🚀📚 #KnowledgeHub #StayInformed #upscinfact",

    'Unlock the path to success with our Instagram channel! 🌟📚 Elevate your chances of acing UPSC, SSC, and NDA exams by staying tuned to our daily posts. We provide a comprehensive array of information, spanning current affairs and general knowledge, equipping you with the tools needed to excel in these competitive exams. Broaden your understanding across diverse subjects while staying updated with the latest events, all in one place. Join us today and empower your journey towards triumph! 🌐🎯 #ExamSuccess #KnowledgeBoost #upscinfact',

    "Ignite your preparation for UPSC, SSC, and NDA exams through our Instagram channel! 📖🌄 Dive into a reservoir of current affairs and general knowledge that's tailored to propel you towards success. With daily doses of insightful posts, we're committed to shaping your understanding of crucial topics, enabling you to confidently navigate your exams. Fuel your learning with us and embrace a brighter academic future! 🚀📝 #ExamPrep #StayInformed #upscinfact",

    'Welcome to your daily source of knowledge on Instagram! 🌐📚 Get ready to conquer UPSC, SSC, and NDA exams with our meticulously crafted content. We offer a seamless blend of current affairs and diverse educational insights that empower you to tackle the toughest questions. Stay engaged, stay informed, and pave the way for your triumph in the world of competitive exams. 🌈📖 #KnowledgeGateway #ExamMastery #upscinfact',

    'Prepare to excel in UPSC, SSC, and NDA exams with the guidance of our Instagram channel! 🌠📚 Immerse yourself in a world of curated current affairs and enlightening information that will set you apart from the competition. Our daily posts are designed to expand your intellectual horizons and equip you with the knowledge needed to crack these exams with confidence. Join us now to embark on a journey of educational empowerment! 🚀📚 #ExamAchievement #StayInformed #upscinfact',

    "Dive into a realm of learning with our Instagram channel! 📚🌍 We're your companion for UPSC, SSC, and NDA exams, offering a treasure trove of knowledge that spans a multitude of subjects. Stay engaged with our insightful posts, and equip yourself with the tools necessary to navigate the complexities of these competitive exams. Join us on this enlightening journey today! 🚀📖 #LearnWithUs #ExamPreparation #upscinfact",

    "Elevate your exam readiness with our Instagram channel! 🌟📚 Tailored for UPSC, SSC, and NDA exams, our content serves as your daily dose of enlightenment. Immerse yourself in a world of facts, figures, and current affairs that are crucial for your success. With our guidance, you'll be equipped to face your exams with confidence and stride toward excellence. 🚀🎓 #ExamBound #KnowledgeHub #upscinfact",

    "Welcome to a world of knowledge on Instagram! 🌐📚 Our platform is your key to mastering UPSC, SSC, and NDA exams. Delve into a repository of carefully curated information that spans various subjects and domains. Our commitment to your educational journey ensures that you're well-prepared to tackle even the most challenging questions. Join us today and chart your course to triumph! 🚀📖 #ExamVictory #StayInformed #upscinfact",

    "Fuel your exam preparation through our Instagram channel! 🔥📚 We're dedicated to helping you conquer UPSC, SSC, and NDA exams with our daily insights. Dive deep into the world of current affairs and general knowledge, equipping yourself with a holistic understanding of the subjects that matter most. With us by your side, you're poised for success in your academic pursuits! 🌟🎯 #KnowledgeBoost #ExamExcellence #upscinfact",

    "Navigate the path to exam success with our Instagram channel! 🌄📚 Whether it's UPSC, SSC, or NDA, we've got you covered with a wealth of educational content. Immerse yourself in a universe of facts, analysis, and insights that will enhance your exam preparedness. Stay informed, stay motivated, and embark on this transformative journey with us. 🚀📖 #ExamJourney #StayInformed #upscinfact"
]

function getRandomCaptionParagraph() {
    const randomIndex = Math.floor(Math.random() * paragraphs.length)
    return paragraphs[randomIndex]
}

const shuffle = (array) => {
    let currentIndex = array.length,
        randomIndex

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

        // And swap it with the current element.
        ;[array[currentIndex], array[randomIndex]] = [
            array[randomIndex],
            array[currentIndex]
        ]
    }

    return array
}

const findDuplicates = (arr) => {
    let sorted_arr = arr.slice().sort()
    let results = []
    for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] === sorted_arr[i]) {
            results.push(sorted_arr[i])
        }
    }
    return results
}

exports.hashtagsGenerator = () => {
    return hashtagGeneratorHelper()
}

hashtagGeneratorHelper()
