import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import axios from 'axios';
import { BASE_URL } from '../../Api/Constants';

const data = [
    { name: 'Jan', pv: 2400 },
    { name: 'Feb', pv: 1398 },
    { name: 'Mar', pv: 9800 },
    { name: 'Apr', pv: 3908 },
    { name: 'May', pv: 4800 },
    { name: 'Jun', pv: 3800 },
    { name: 'Jul', pv: 4300 }
];

function Home() {

    const [questionCount, setQuestionCount] = useState(0);
    const [usersCount, setUsersCount] = useState(0);
    const [quizCount, setQuizCount] = useState(0);
    const [adminCount, setAdminCount] = useState(0);

    useEffect(() => {
        axios.get(`${BASE_URL}/api/v1/stats`)
            .then(response => {
                const data = response.data.data;
                setQuestionCount(data.questionCount);
                setUsersCount(data.usersCount);
                setQuizCount(data.quizCount);
                setAdminCount(data.adminCount);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);

    const analysisPoints = [
        {
            label: 'Questions',
            value: questionCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/405420_624084.png'
        },
        {
            label: 'Users',
            value: usersCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/212209_449602.png',
        },
        {
            label: 'Quizzes',
            value: quizCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/896099_357473.png'
        },
        {
            label: 'Admin',
            value: adminCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/619992_693225.png'
        },
    ];

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box sx={{ flexGrow: 1, p: 3 }}>
                    <Typography variant="h4" sx={{ mb: 4 }}>Dashboard</Typography>
                    <Grid container spacing={3}>
                        {analysisPoints.map((point, index) => (
                            <Grid item xs={12} md={3} key={index}>
                                <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <img src={point.icon} alt={point.label} style={{ width: 50, height: 50 }} />
                                    <Typography variant="h6">{point.label}</Typography>
                                    <Typography variant="h4">{point.value}</Typography>
                                </Paper>
                            </Grid>
                        ))}

                        {/* Line Chart */}
                        <Grid item xs={6}>
                            <Paper sx={{ p: 2 }}>
                                <Typography variant="h6" gutterBottom>Quizzes Over Time</Typography>
                                <ResponsiveContainer width="100%" height={400}>
                                    <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>

                        {/* Bar Chart */}
                        <Grid item xs={6}>
                            <Paper sx={{ p: 2 }}>
                                <Typography variant="h6" gutterBottom>Users</Typography>
                                <ResponsiveContainer width="100%" height={400}>
                                    <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="pv" fill="#8884d8" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}

export default Home;
