import './GetOneTimedFact.css'

import * as React from 'react';
import { useEffect } from 'react';
import { CircularProgress, Grid, Snackbar, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import MuiAlert from '@mui/material/Alert';
import { useSearchParams } from 'react-router-dom';
import { getLiveEndpoint } from '../../../Utils/url';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GetOneTimedFact = () => {

    const [apiCalled, setApiCalled] = useState(false);
    const [blockedDomains, setBlockedDomains] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()

    const [currentDay, setCurrentDay] = useState('')
    const [currentMonth, setCurrentMonth] = useState('')

    // Modals
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    // Edit Fact Modal
    const [modalOpenEditFact, setModalOpenEditFact] = React.useState(false);
    const handleModalOpenEditFact = () => setModalOpenEditFact(true);
    const handleModalCloseEditFact = () => setModalOpenEditFact(false);

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const resetAllFields = () => {
        setApiCalled(false)
    }

    const copyFactHandler = (text) => {
        navigator.clipboard.writeText(text);
        setOpenSuccessAlert(true);
        setSuccessMessage(`${text} - has been copied to clipboard.`)
    }

    const getBlockedListOfUser = (day, month) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "day": Number(day),
                "month": Number(month),
            })
        };

        fetch(getLiveEndpoint(true, 'api/upsc/datedfacts/fact'), requestOptions)
            .then(response => response.json())
            .then((data) => {
                if (data.status === 200) {
                    setApiCalled(true);
                    const array = data.data.facts
                    setBlockedDomains(array);
                } else if (data.status === 401) {
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const getMonthNameFromNumber = (monthIndex) => {
        let monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return monthsArray[monthIndex - 1];
    }

    useEffect(() => {
        const day = searchParams.get("day")
        setCurrentDay(day)
        const month = searchParams.get("month")
        setCurrentMonth(getMonthNameFromNumber(month))
        getBlockedListOfUser(day,month)
    }, [])

    return (
        <Box sx={{ flexGrow: 2 }}>
            <Grid align="center" justify="center" spacing={2}>

                <Grid item lg={4} md={6} sm={12} className="py-5">
                    <h1 className="header-top mt-5"> {currentDay} {currentMonth}</h1>
                </Grid>

                <div class="main-div-snippets-each-day mb-20">
                {
                    blockedDomains.length > 0 && <Timeline position="alternate">
                    {blockedDomains.map((eachDomain) => {
                        return (
                            <TimelineItem key={eachDomain._id}>
                                <TimelineOppositeContent color="text.primary">
                                    {eachDomain.fact}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot sx={{ fontSize: "50px"}} />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>{eachDomain.year}</TimelineContent>
                            </TimelineItem>
                        )
                    })}
                </Timeline>
                }
                {
                    blockedDomains.length === 0 && <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '300px' }}>
                        <CircularProgress size="7rem" color="grey" />
                    </Box>
                }
            </div>
            </Grid>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </Box>
    )
}

export default GetOneTimedFact;