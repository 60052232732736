exports.capitalizeTag = (tag) => {
    if (tag === "generalKnowledge") {
        return "General Knowledge";
    } else if (tag === "national") {
        return "National";
    } else if (tag === "international") {
        return "International";
    } else if (tag === "geography") {
        return "Geography";
    } else if (tag === "history") {
        return "History";
    } else if (tag === "polity") {
        return "Polity";
    } else if (tag === "sports") {
        return "Sports";
    } else if (tag === "science") {
        return "Science";
    } else if (tag === "defence") {
        return "Defence";
    } else if (tag === "technology") {
        return "Technology";
    } else if (tag === "organizations") {
        return "Organizations";
    } else if (tag === "reportsAndIndices") {
        return "Reports and Indices";
    } else if (tag === "awardsAndHonours") {
        return "Awards and Honours";
    } else if (tag === "daysAndEvents") {
        return "Days and Events";
    } else if (tag === "economy") {
        return "Economy";
    } else if (tag === "year") {
        return "Year";
    } else if (tag === "constitution") {
        return "Constitution";
    } else if (tag === "agriculture") {
        return "Agriculture";
    } else if (tag === "personality") {
        return "Personality";
    } else if (tag === "culture") {
        return "Culture";
    } else if (tag === "environment") {
        return "Environment";
    } else if (tag === "biology") {
        return "Biology";
    }
    return tag;
};

exports.initialTagStates = () => {
    return {
        generalKnowledge: false,
        national: false,
        international: false,
        sports: false,
        science: false,
        geography: false,
        history: false,
        polity: false,
        defence: false,
        technology: false,
        organizations: false,
        reportsAndIndices: false,
        awardsAndHonours: false,
        daysAndEvents: false,
        economy: false,
        year: false,
        constitution: false,
        agriculture: false,
        personality: false,
        culture: false,
        environment: false,
        biology: false,
    };
};
