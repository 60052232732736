import './Minify.css'
import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// For Alert Stacks
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';

import { IconButton, Input, Table, TableBody } from "@mui/material";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { capitalizeTag, initialTagStates } from '../../../Utils/tags';
import { getLiveEndpoint } from '../../../Utils/url';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Done } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Minify = () => {

    const [fact, setFact] = useState('')

    const [miniFact, setMiniFact] = useState('')
    const [miniAnswer, setMiniAnswer] = useState('')
    const [yearFact, setYearFact] = useState('')
    const [yearAnswer, setYearAnswer] = useState('')

    const [successMessage, setSuccessMessage] = useState('Success!!!')
    const [errorMessage, setErrorMessage] = useState('Error!!!')

    const [state, setState] = React.useState(initialTagStates);

    const [apiCalled, setApiCalled] = useState(false);
    const [blockedDomains, setBlockedDomains] = useState([])

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const { generalKnowledge, national, international, sports, science, geography,
        history, polity, defence, technology, organizations, reportsAndIndices,
        awardsAndHonours, daysAndEvents, economy, year, constitution, agriculture,
        personality, culture, environment, biology } = state;

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const getTagsArrayFromState = () => {
        const serializedTags = []
        for (const [key, value] of Object.entries(state)) {
            console.log(`${key}: ${value}`);
            if (value === true) {
                serializedTags.push(capitalizeTag(key))
            }
        }
        return serializedTags
    }

    const getFactsForMinifying = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(getLiveEndpoint(true, 'api/upsc/minify/facts'), requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    setApiCalled(true);
                    const array = data.data.facts
                    setBlockedDomains(array);
                } else if (data.status === 401) {
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                    // resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const clearTagStatus = () => {
        setState({
            generalKnowledge: false,
            national: false,
            international: false,
            sports: false,
            science: false,
            geography: false,
            history: false,
            polity: false,
            defence: false,
            technology: false,
            organizations: false,
            reportsAndIndices: false,
            awardsAndHonours: false,
            daysAndEvents: false,
            economy: false,
            year: false,
            constitution: false,
            agriculture: false,
            personality: false,
            culture: false,
            environment: false,
            biology: false
        })
    }

    const minifiedFactHandler = (factID) => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "factId": factID })
        };

        fetch(getLiveEndpoint(true, 'api/upsc/minify/facts'), requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('Fact has been minified!!')
                    setOpenSuccessAlert(true)
                    getFactsForMinifying()
                    clearTagStatus()
                    setBlockedDomains((blockedDomains) => {
                        return blockedDomains.filter((product) => product._id !== factID)
                    })
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    setErrorMessage(data.data.error || 'Error')
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    const addMiniFactHandler = () => {
        const tags = getTagsArrayFromState()

        if (miniFact === '' || miniAnswer === '') {
            setErrorMessage('Please enter full details to upload fact')
            setOpenErrorAlert(true)
            return
        }

        if (!tags.length) {
            setErrorMessage('Please select atleast one tag to upload.')
            setOpenErrorAlert(true)
            return
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "fact": miniFact,
                "answer": miniAnswer,
                "tags": tags,
            })
        };

        fetch('https://api.studencity.in/api/upsc/minifacts', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage(data.data.info)
                    setOpenSuccessAlert(true)
                    setMiniFact('')
                    setMiniAnswer('')
                    clearTagStatus()
                } else {
                    setErrorMessage(data.data.error)
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    const addYearFactHandler = () => {
        const tags = getTagsArrayFromState()

        if (yearFact === '' || yearAnswer === '') {
            setErrorMessage('Please enter full details to upload fact')
            setOpenErrorAlert(true)
            return
        }

        if (!tags.length) {
            setErrorMessage('Please select atleast one tag to upload.')
            setOpenErrorAlert(true)
            return
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "fact": yearFact,
                "year": yearAnswer,
                "tags": tags,
            })
        };

        fetch('https://api.studencity.in/api/upsc/yearfacts', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage(data.data.info)
                    setOpenSuccessAlert(true)
                    setYearFact('')
                    clearTagStatus()
                    setYearAnswer('')
                } else {
                    setErrorMessage(data.data.error)
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            getFactsForMinifying()
        }
    }, [])

    return (
        <Box sx={{ flexGrow: 2 }}>
            <h1 className="header-top my-5">Minify these facts</h1>
            <Grid align="center" justify="center" spacing={2}>
                <Table sx={{ width: "80%" }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Fact</StyledTableCell>
                            <StyledTableCell>Done</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {apiCalled && blockedDomains.map((eachDomain) => {
                        return (
                            <TableBody>
                                <StyledTableRow key={eachDomain._id}>
                                    <StyledTableCell component="th" scope="row">
                                        {eachDomain.fact}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        <IconButton aria-label="delete" onClick={() => minifiedFactHandler(eachDomain._id)} sx={{ marginX: 1 }}>
                                            <Done style={{ fontSize: 30, color: "#2874A6" }} />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        )
                    })}
                </Table>
            </Grid>
            <Grid align="center" justify="center" spacing={2}>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <FormControl sx={{ m: 1, width: "80%" }} component="fieldset" variant="standard">
                        <FormLabel component="legend">Assign tags</FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={generalKnowledge} onChange={handleChange} name="generalKnowledge" />
                                }
                                label="General Knowledge"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={national} onChange={handleChange} name="national" />
                                }
                                label="National"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={international} onChange={handleChange} name="international" />
                                }
                                label="International"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={sports} onChange={handleChange} name="sports" />
                                }
                                label="Sports"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={science} onChange={handleChange} name="science" />
                                }
                                label="Science"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={geography} onChange={handleChange} name="geography" />
                                }
                                label="Geography"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={history} onChange={handleChange} name="history" />
                                }
                                label="History"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={polity} onChange={handleChange} name="polity" />
                                }
                                label="Polity"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={defence} onChange={handleChange} name="defence" />
                                }
                                label="Defence"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={technology} onChange={handleChange} name="technology" />
                                }
                                label="Technology"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={organizations} onChange={handleChange} name="organizations" />
                                }
                                label="Organizations"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={reportsAndIndices} onChange={handleChange} name="reportsAndIndices" />
                                }
                                label="Reports and Indices"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={awardsAndHonours} onChange={handleChange} name="awardsAndHonours" />
                                }
                                label="Awards and Honours"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={daysAndEvents} onChange={handleChange} name="daysAndEvents" />
                                }
                                label="Days and Events"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={economy} onChange={handleChange} name="economy" />
                                }
                                label="Economy"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={year} onChange={handleChange} name="year" />
                                }
                                label="Year"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={constitution} onChange={handleChange} name="constitution" />
                                }
                                label="Constitution"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={agriculture} onChange={handleChange} name="agriculture" />
                                }
                                label="Agriculture"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={personality} onChange={handleChange} name="personality" />
                                }
                                label="Personality"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={culture} onChange={handleChange} name="culture" />
                                }
                                label="Culture"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={environment} onChange={handleChange} name="environment" />
                                }
                                label="Environment"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox checked={biology} onChange={handleChange} name="biology" />
                                }
                                label="Biology"
                            />
                        </FormGroup>
                        <FormHelperText>Choose appropriate tags</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Input
                        disablePortal
                        placeholder="Enter Mini fact..."
                        id="combo-box-demo"
                        multiline
                        value={miniFact}
                        maxRows={5}
                        sx={{ width: "35%", marginX: "10px" }}
                        onChange={(event, item) => {
                            setMiniFact(event.target.value)
                        }}
                    />

                    <Input
                        disablePortal
                        placeholder="Enter Year fact..."
                        id="combo-box-demo"
                        multiline
                        value={yearFact}
                        maxRows={5}
                        sx={{ width: "35%", marginX: "10px" }}
                        onChange={(event, item) => {
                            setYearFact(event.target.value)
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Input
                        disablePortal
                        placeholder="Mini Answer"
                        id="combo-box-demo"
                        multiline
                        value={miniAnswer}
                        maxRows={5}
                        sx={{ width: "35%", marginX: "10px" }}
                        onChange={(event, item) => {
                            setMiniAnswer(event.target.value)
                        }}
                    />

                    <Input
                        disablePortal
                        placeholder="Year answer"
                        id="combo-box-demo"
                        multiline
                        value={yearAnswer}
                        maxRows={5}
                        sx={{ width: "35%", marginX: "10px" }}
                        onChange={(event, item) => {
                            setYearAnswer(event.target.value)
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Button
                        className="h-12"
                        sx={{ width: "35%", marginX: "10px" }}
                        onClick={addMiniFactHandler}
                        // color="success"
                        variant="contained">
                        Add Mini fact
                    </Button>
                    <Button
                        className="h-12"
                        sx={{ width: "35%", marginX: "10px" }}
                        onClick={addYearFactHandler}
                        // color="success"
                        variant="contained">
                        Add Year fact
                    </Button>
                </Grid>
            </Grid>


            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </Box >
    );
};

export default Minify;
