import './GetTimedFacts.css'

import * as React from 'react';
import { useEffect } from 'react';
import { CircularProgress, Grid, Snackbar, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import { getLiveEndpoint } from '../../../Utils/url';
import DrawerNav from '../../Navigation/DrawerNav';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GetTimedFacts = () => {

    const [apiCalled, setApiCalled] = useState(false);
    const [blockedDomains, setBlockedDomains] = useState([])
    const navigate = useNavigate()

    const [todoTopic, setTodoTopic] = useState('')
    const [completeTodoId, setCompleteTodoId] = useState('')

    const [scheduledPostId, setScheduledPostId] = useState('')
    const [scheduledPostDate, setScheduledPostDate] = useState(0)

    const [editFactText, setEditFactText] = useState('')
    const [editFactId, setEditFactId] = useState('')

    // Modals
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    // Edit Fact Modal
    const [modalOpenEditFact, setModalOpenEditFact] = React.useState(false);
    const handleModalOpenEditFact = () => setModalOpenEditFact(true);
    const handleModalCloseEditFact = () => setModalOpenEditFact(false);

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const resetAllFields = () => {
        setApiCalled(false)
    }

    const copyFactHandler = (text) => {
        navigator.clipboard.writeText(text);
        setOpenSuccessAlert(true);
        setSuccessMessage(`${text} - has been copied to clipboard.`)
    }

    const getBlockedListOfUser = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(getLiveEndpoint(true, 'api/upsc/datedfacts/daywise'), requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    setApiCalled(true);
                    const array = data.data.facts
                    setBlockedDomains(array);
                } else if (data.status === 401) {
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const getMonthNameFromNumber = (monthIndex) => {
        let monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return monthsArray[monthIndex - 1];
    }

    const eachFactRouteHandler = (day, month) => {
        navigate(`/get-each-timed-facts?day=${day}&month=${month}`)
    }

    useEffect(() => {
        if (!apiCalled) {
            getBlockedListOfUser()
        }
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box sx={{ flexGrow: 2 }}>
                <Grid align="center" justify="center" spacing={2}>

                    <Grid item lg={4} md={6} sm={12} className="py-5">
                        <h1 className="header-top mt-5"> On This Day</h1>
                    </Grid>

                    <div class="main-div-snippets mb-20">
                        {
                            blockedDomains.length > 0 && blockedDomains.map((item, i) => {
                                return (
                                    <div className="cursor-pointer card-list-snippets" key={i} onClick={() => eachFactRouteHandler(item.day, item.month)}>
                                        <article class="card-snippets">
                                            <div>
                                                <h3 className='font-semibold my-4'><span className='font-normal'>{item.day} {getMonthNameFromNumber(item.month)} has</span> {item.count} <span className='font-normal'>facts.</span></h3>
                                            </div>
                                        </article>
                                    </div>
                                )
                            })
                        }
                        {
                            blockedDomains.length === 0 && <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '300px' }}>
                                <CircularProgress size="7rem" color="grey" />
                            </Box>
                        }
                    </div>
                </Grid>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
            </Box>
        </Box>
    )
}

export default GetTimedFacts;