import './PersonalityFact.css'
import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// For Alert Stacks
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';

import { Autocomplete, IconButton, Input, TextField } from "@mui/material";
import { getLiveEndpoint } from '../../../Utils/url';

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Done } from '@mui/icons-material';
import { Table, TableBody } from "@mui/material";
import DrawerNav from '../../Navigation/DrawerNav';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PersonalityFact = () => {

    const [fact, setFact] = useState('')
    const [personality, setPersonality] = useState('')

    const [newFact, setNewFact] = useState('')
    const [newPersonality, setNewPersonality] = useState('')

    const [blockedDomains, setBlockedDomains] = useState([])
    const [successMessage, setSuccessMessage] = useState('Success!!!')
    const [errorMessage, setErrorMessage] = useState('Error!!!')

    const [optionsList, setOptionsList] = useState([
        {
            label: 'Mahatma Gandhi'
        },
        {
            label: 'Narendra Modi'
        },
        {
            label: 'Jawaharlal Nehru'
        },
        {
            label: 'Bhagat Singh'
        }
    ])

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const addNewFactHandler = () => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "fact": fact,
                "personality": personality
            })
        };

        fetch('https://api.studencity.in/api/upsc/personalityfacts', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('Fact has been uploaded.')
                    setOpenSuccessAlert(true)
                    setFact('')
                    setPersonality('')
                } else {
                    setErrorMessage(data.data.error)
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    const addNewFactHandlerForFirstTime = () => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "fact": newFact,
                "personality": newPersonality
            })
        };

        fetch('https://api.studencity.in/api/upsc/personalityfacts', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('Fact has been uploaded.')
                    setOpenSuccessAlert(true)
                    setNewFact('')
                    setNewPersonality('')
                } else {
                    setErrorMessage(data.data.error)
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    const getPersonalitiesList = () => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch('https://api.studencity.in/api/upsc/personalities', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setOptionsList(data.data.list)
                } else {
                    setErrorMessage(data.data.error)
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    const minifiedFactHandler = (factID) => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "factId": factID })
        };

        fetch(getLiveEndpoint(true, 'api/upsc/personalityfacts/mark'), requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('Fact has been personified!!')
                    setOpenSuccessAlert(true)
                    getFactsForMinifying()
                    setBlockedDomains((blockedDomains) => {
                        return blockedDomains.filter((product) => product._id !== factID)
                    })
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    setErrorMessage(data.data.error || 'Error')
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    const getFactsForMinifying = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(getLiveEndpoint(true, 'api/upsc/personalityfacts'), requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    const array = data.data.facts
                    setBlockedDomains(array);
                } else if (data.status === 401) {
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    useEffect(() => {
        getPersonalitiesList()
        getFactsForMinifying()
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />

            <Box sx={{ flexGrow: 2 }}>
                <Grid align="center" justify="center" spacing={2} className='mt-10'>
                    <Table sx={{ width: "80%" }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Fact</StyledTableCell>
                                <StyledTableCell>Done</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {blockedDomains.length > 0 && blockedDomains.map((eachDomain) => {
                            return (
                                <TableBody>
                                    <StyledTableRow key={eachDomain._id}>
                                        <StyledTableCell component="th" scope="row">
                                            {eachDomain.fact}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <IconButton aria-label="delete" onClick={() => minifiedFactHandler(eachDomain._id)} sx={{ marginX: 1 }}>
                                                <Done style={{ fontSize: 30, color: "#2874A6" }} />
                                            </IconButton>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            )
                        })}
                    </Table>
                </Grid>
                <h1 className="header-top mt-5">Add Personality Fact</h1>
                <Grid align="center" justify="center" spacing={2}>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Input
                            disablePortal
                            placeholder="Enter fact"
                            id="combo-box-demo"
                            multiline
                            value={fact}
                            maxRows={5}
                            sx={{ width: "700px" }}
                            onChange={(event, item) => {
                                setFact(event.target.value)
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Autocomplete
                            disableportal="true"
                            id="combo-box-demo"
                            options={optionsList}
                            value={personality}
                            sx={{ width: "700px" }}
                            onChange={(event, item) => {
                                if (item === null) return
                                setPersonality(item.label)
                            }}
                            renderInput={(params) => <TextField {...params} label="Choose personality" />}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Button
                            className="h-12"
                            sx={{ width: "700px" }}
                            onClick={addNewFactHandler}
                            variant="contained">
                            Add fact
                        </Button>
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className="pt-20">
                        <Input
                            disablePortal
                            placeholder="Enter fact name"
                            id="combo-box-demo"
                            multiline
                            value={newFact}
                            maxRows={5}
                            sx={{ width: "700px" }}
                            onChange={(event, item) => {
                                setNewFact(event.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Input
                            disablePortal
                            placeholder="Enter personality name"
                            id="combo-box-demo"
                            multiline
                            value={newPersonality}
                            sx={{ width: "700px" }}
                            onChange={(event, item) => {
                                setNewPersonality(event.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Button
                            className="h-12"
                            sx={{ width: "700px" }}
                            onClick={addNewFactHandlerForFirstTime}
                            color='success'
                            variant="contained">
                            Add fact for Personality
                        </Button>
                    </Grid>
                </Grid>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
            </Box>
        </Box>
    );
};

export default PersonalityFact;
