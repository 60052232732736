import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery, Badge } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore, Grid3x3 } from '@mui/icons-material';
import { navItems, logoutItem, secondaryItems } from './NavItems';

function DrawerNav() {
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width:600px)');
    const [open, setOpen] = useState(false);
    const [factsCounts, setFactsCounts] = useState({ view: 4, build: 10, review: 5 });

    useEffect(() => {
        const fetchFactsCounts = async () => {
            try {
                const response = await axios.get('/api/facts-counts');
                setFactsCounts({
                    view: response.data.viewFacts,
                    build: response.data.buildFacts,
                    review: response.data.reviewFacts,
                });
            } catch (error) {
                console.error('Error fetching facts counts', error);
            }
        };

        fetchFactsCounts();
    }, []);

    const handleRoute = (url) => {
        if (url === 'login') {
            localStorage.clear();
        }
        navigate('/' + url);
    };

    const handleClick = () => {
        setOpen(!open);
    };

    const drawerWidth = 300;

    return (
        <>
            <CssBaseline />
            <Drawer
                variant='permanent'
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    display: isMobile ? 'none' : 'block',
                    position: 'relative',
                    zIndex: 1
                }}>
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {navItems.map((item) => (
                            <ListItem
                                key={item.value}
                                disablePadding
                                sx={{
                                    backgroundColor: location.pathname === '/' + item.route ? '#F0F0F0' : 'inherit'
                                }}>
                                <ListItemButton onClick={() => handleRoute(item.route)}>
                                    <ListItemIcon>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={item.value + (item.value === 'View Facts' ? ` - ${factsCounts.view}` :
                                        item.value === 'Build Facts' ? ` - ${factsCounts.build}` :
                                            item.value === 'Review Facts' ? ` - ${factsCounts.review}` : '')} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleClick}>
                                <ListItemIcon>
                                    <Grid3x3 />
                                </ListItemIcon>
                                <ListItemText primary="Others" />
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {secondaryItems.map((item) => (
                                    <ListItem
                                        key={item.value}
                                        disablePadding
                                        sx={{
                                            backgroundColor: location.pathname === '/' + item.route ? '#F0F0F0' : 'inherit',
                                            pl: 4
                                        }}>
                                        <ListItemButton onClick={() => handleRoute(item.route)}>
                                            <ListItemIcon>
                                                    {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.value} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    </List>
                    <Divider />
                    <List>
                        <ListItem
                            key={logoutItem.value}
                            disablePadding
                            sx={{
                                backgroundColor: location.pathname === '/' + logoutItem.route ? 'lightgray' : 'inherit'
                            }}>
                            <ListItemButton onClick={() => handleRoute(logoutItem.route)}>
                                <ListItemIcon>{logoutItem.icon}</ListItemIcon>
                                <ListItemText primary={logoutItem.value} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </>
    );
}

export default DrawerNav;
