import './BuildFact.css'

import * as React from 'react';
import { useEffect } from 'react';
import { Button, Grid, IconButton, Input, Modal, Snackbar, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CopyIcon from '@mui/icons-material/CopyAll'
import { Build, Create, Tag } from '@mui/icons-material';

import { hashtagsGenerator } from '../../../Helper/hashtags';
import DrawerNav from '../../Navigation/DrawerNav';

const modalSyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'white',
    border: '2px solid gray',
    boxShadow: 24,
    p: 4,
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function convertMillisecondsToDate(ms) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(ms).toLocaleDateString(undefined, options);
}

const BuildFact = () => {

    const [apiCalled, setApiCalled] = useState(false);
    const [blockedDomains, setBlockedDomains] = useState([])

    const [scheduledPostId, setScheduledPostId] = useState('')
    const [scheduledPostDate, setScheduledPostDate] = useState(0)

    const [editFactText, setEditFactText] = useState('')
    const [editFactId, setEditFactId] = useState('')

    // Modals
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    // Edit Fact Modal
    const [modalOpenEditFact, setModalOpenEditFact] = React.useState(false);
    const handleModalOpenEditFact = () => setModalOpenEditFact(true);
    const handleModalCloseEditFact = () => setModalOpenEditFact(false);

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const resetAllFields = () => {
        setApiCalled(false)
    }

    const copyFactHandler = (text) => {
        navigator.clipboard.writeText(text);
        setOpenSuccessAlert(true);
        setSuccessMessage(`${text} - has been copied to clipboard.`)
    }

    const copyHashtagsForPost = (sentence) => {
        const result = hashtagsGenerator(sentence)
        navigator.clipboard.writeText(sentence + '\n' + result);
        setSuccessMessage(`${result} - has been copied to clipboard.`)
        setOpenSuccessAlert(true);
    }


    const editFactHandler = (id, fact) => {
        setEditFactId(id)
        setEditFactText(fact)
        handleModalOpenEditFact()
    }

    const editFactUploadHandler = () => {
        handleModalCloseEditFact()
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "factId": editFactId, "updatedFact": editFactText })
        };

        console.log(requestOptions)

        fetch('https://api.studencity.in/api/upsc/generalfacts/edit', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('Fact has been edited!!')
                    setOpenSuccessAlert(true)
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log(' ERROR : ', error)
                setOpenErrorAlert(true)
            })

        setBlockedDomains((blockedDomains) => {
            return blockedDomains.filter((product) => product._id !== scheduledPostId)
        })
    }

    const getBlockedListOfUser = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch('https://api.studencity.in/api/upsc/instagram/scheduledfacts', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    setApiCalled(true);
                    const array = data.data.generalFacts
                    setBlockedDomains(array);
                } else if (data.status === 401) {
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const instagramBuildHandler = (id) => {
        schedulePostButtonHandler(id)
    }

    const schedulePostButtonHandler = (factid) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "factId": factid })
        };

        fetch('https://api.studencity.in/api/upsc/instagram/build', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('Fact Build Complete!!')
                    setOpenSuccessAlert(true)
                    setBlockedDomains((blockedDomains) => {
                        return blockedDomains.filter((product) => product._id !== factid)
                    })
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const capitalizeTag = (tag) => {
        if (tag === 'generalKnowledge') {
            return 'General Knowledge'
        } else if (tag === 'national') {
            return 'National'
        }
        return tag
    }

    useEffect(() => {
        if (!apiCalled) {
            getBlockedListOfUser()
        }
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />

            <Box sx={{ flexGrow: 2 }}>
                <Grid align="center" justify="center" spacing={2}>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <h1 className="header-top mt-5"> Build Facts</h1>
                    </Grid>

                    {apiCalled === true ?
                        <>
                            <Table sx={{ width: "95%", marginTop: 10, marginBottom: 30 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Fact</StyledTableCell>
                                        <StyledTableCell>Copy</StyledTableCell>
                                        <StyledTableCell>Hashtags</StyledTableCell>
                                        <StyledTableCell>Edit</StyledTableCell>
                                        <StyledTableCell align="left">Scheduled On</StyledTableCell>
                                        <StyledTableCell align="left">Build</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                {blockedDomains.map((eachDomain) => {
                                    return (
                                        <TableBody>
                                            <StyledTableRow key={eachDomain._id}>
                                                <StyledTableCell component="th" scope="row">
                                                    {eachDomain.fact}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <IconButton aria-label="delete" onClick={() => copyFactHandler(eachDomain.fact)}>
                                                        <CopyIcon style={{ fontSize: 30, color: "#0F52BA" }} />
                                                    </IconButton>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <IconButton aria-label="delete" onClick={() => copyHashtagsForPost(eachDomain.fact)}>
                                                        <Tag style={{ fontSize: 30, color: "#FF5733" }} />
                                                    </IconButton>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <IconButton aria-label="delete" onClick={() => editFactHandler(eachDomain._id, eachDomain.fact)}>
                                                        <Create style={{ fontSize: 30, color: "#ff2a26" }} />
                                                    </IconButton>
                                                </StyledTableCell>
                                                <StyledTableCell align="left" sx={{ width: "15%" }}>
                                                    {convertMillisecondsToDate(eachDomain.scheduledAt)}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <IconButton aria-label="delete" onClick={() => instagramBuildHandler(eachDomain._id)}>
                                                        <Build className='instaIcon' style={{ fontSize: 30 }} />
                                                    </IconButton>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    )
                                })}
                            </Table>
                        </> : ''}
                </Grid>

                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalSyle}>
                        <Box sx={{ flexGrow: 2 }}>
                            <Grid align="center" justify="center" spacing={2}>

                                <Grid item lg={4} md={6} sm={12} className="pt-5">
                                    <h6 className="header-top mt-5"> Choose Scheduled post date</h6>
                                </Grid>
                                <Grid item lg={4} md={6} sm={12} className="pt-5">
                                    <TextField
                                        label="Start Date"
                                        type='date'
                                        InputLabelProps={{ shrink: true }}
                                        sx={{ width: "300px", margin: "1%" }}
                                        onChange={(event, item) => {
                                            const myDate = event.target.value.split("-");
                                            const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime();
                                            setScheduledPostDate(newDate)
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={4} md={6} sm={12} className="pt-5">
                                    <Button
                                        className="h-12"
                                        variant="contained"
                                        color='success'
                                        sx={{ width: "30%" }}
                                        onClick={schedulePostButtonHandler}>
                                        Schedule
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>

                    </Box>
                </Modal>

                <Modal
                    open={modalOpenEditFact}
                    onClose={handleModalCloseEditFact}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalSyle}>
                        <Box sx={{ flexGrow: 2 }}>
                            <Grid align="center" justify="center" spacing={2}>

                                <Grid item lg={4} md={6} sm={12} className="">
                                    <h6 className="header-top"> Edit Fact</h6>
                                </Grid>
                                <Grid item lg={4} md={6} sm={12} className="pt-5">
                                    <Input
                                        disablePortal
                                        placeholder="Enter fact"
                                        id="combo-box-demo"
                                        multiline
                                        value={editFactText}
                                        maxRows={5}
                                        sx={{ width: "600px" }}
                                        onChange={(event, item) => {
                                            setEditFactText(event.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={4} md={6} sm={12} className="pt-10">
                                    <Button
                                        className="h-12"
                                        variant="contained"
                                        sx={{ width: "30%" }}
                                        onClick={editFactUploadHandler}>
                                        Edit Fact
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>

                    </Box>
                </Modal>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
            </Box>
        </Box>
    )
}

export default BuildFact;