import './App.css'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

// Components
import Home from './Components/Home/Home'
import Header from './Components/Navigation/Header'
import AddFact from './Components/AddFact/AddFact'
import GetFacts from './Components/Upscinfact/GetFacts/GetFacts'
import UploadedFacts from './Components/Upscinfact/UploadedFacts/UploadedFacts'
import ReviewFacts from './Components/Upscinfact/ReviewFact/ReviewFact'
import Minifact from './Components/Upscinfact/Minifact/Minifact'
import GetMiniFacts from './Components/Upscinfact/GetMiniFacts/GetMiniFacts'
import Calendar from './Components/Upscinfact/Calendar/Calendar'
import Progress from './Components/Upscinfact/Progress/Progress'
import GetYearFacts from './Components/Upscinfact/GetYearFacts/GetYearFacts'
import Quiz from './Components/Upscinfact/Quiz/Quiz'
import Links from './Components/Upscinfact/Links/Links'
import BuildFact from './Components/Upscinfact/BuildFact/BuildFact'
import TransientBoard from './Components/TransientBoard/TransientBoard'
import AllFacts from './Components/Upscinfact/AllFacts/AllFacts'
import Minify from './Components/Upscinfact/Minify/Minify'
import PostQuiz from './Components/Upscinfact/PostQuiz/PostQuiz'
import TimedFacts from './Components/Upscinfact/TimedFacts/TimedFacts'
import GetTimedFacts from './Components/Upscinfact/GetTimedFacts/GetTimedFacts'
import Stats from './Components/Upscinfact/Stats/Stats'
import PersonalityFact from './Components/Upscinfact/PersonalityFact/PersonalityFact'
import GetOneTimedFact from './Components/Upscinfact/GetOneTimedFact/GetOneTimedFact'
import PDFAll from './Components/Upscinfact/PDFAll/PDFAll'
import Others from './Components/Others'
import TopicCount from './Components/TopicCount'
import RequestLogs from './Components/RequestLogs/RequestLogs'

function App() {
    return (
        <BrowserRouter>
            <div>
                <Header />
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route exact path='/add' element={<AddFact />} />
                    <Route exact path='/transient-board' element={<TransientBoard />} />
                    
                    <Route exact path='/minify' element={<Minify />} />
                    <Route exact path='/personality' element={<PersonalityFact />} />
                    <Route exact path='/get' element={<GetFacts />} />
                    <Route exact path='/allfacts' element={<AllFacts />} />
                    <Route exact path='/build' element={<BuildFact />} />
                    <Route exact path='/stats' element={<Stats />} />
                    <Route exact path='/uploaded' element={<UploadedFacts />} />
                    <Route exact path='/review' element={<ReviewFacts />} />
                    <Route exact path='/minifact' element={<Minifact />} />
                    <Route exact path='/timed' element={<TimedFacts />} />
                    <Route exact path='/minifacts' element={<GetMiniFacts />} />
                    <Route exact path='/get-timed-facts' element={<GetTimedFacts />} />
                    <Route exact path='/get-each-timed-facts' element={<GetOneTimedFact />} />
                    <Route exact path='/yearfacts' element={<GetYearFacts />} />
                    <Route exact path='/all-pdfs' element={<PDFAll />} />
                    <Route exact path='/calendar' element={<Calendar />} />
                    <Route exact path='/progress' element={<Progress />} />
                    <Route exact path='/storyquiz' element={<Quiz />} />
                    <Route exact path='/postquiz' element={<PostQuiz />} />
                    <Route exact path='/links' element={<Links />} />
                    <Route exact path='/others' element={<Others />} />
                    <Route exact path='/topic-count' element={<TopicCount />} />
                    <Route exact path='/request-logs' element={<RequestLogs />} />
                </Routes>
            </div>
        </BrowserRouter>
    )
}

export default App
