import * as React from 'react';
import { Grid, IconButton, Snackbar, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import MuiAlert from '@mui/material/Alert';
import { Star } from '@mui/icons-material';
import DrawerNav from '../Navigation/DrawerNav';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TransientBoard = () => {
    const [one, setOne] = useState(localStorage.getItem('one'))
    const [two, setTwo] = useState(localStorage.getItem('two'))
    const [three, setThree] = useState(localStorage.getItem('three'))
    const [four, setFour] = useState(localStorage.getItem('four'))
    const [five, setFive] = useState(localStorage.getItem('five'))
    const [six, setSix] = useState(localStorage.getItem('six'))
    const [seven, setSeven] = useState(localStorage.getItem('seven'))
    const [eight, setEight] = useState(localStorage.getItem('eight'))
    const [nine, setNine] = useState(localStorage.getItem('nine'))
    const [ten, setTen] = useState(localStorage.getItem('ten'))
    const [eleven, setEleven] = useState(localStorage.getItem('eleven'))
    const [twelve, setTwelve] = useState(localStorage.getItem('twelve'))
    const [thirteen, setThirteen] = useState(localStorage.getItem('thirteen'))
    const [fourteen, setFourteen] = useState(localStorage.getItem('fourteen'))
    const [fifteen, setFifteen] = useState(localStorage.getItem('fifteen'))
    const [sixteen, setSixteen] = useState(localStorage.getItem('sixteen'))
    const [seventeen, setSeventeen] = useState(localStorage.getItem('seventeen'))
    const [eighteen, setEighteen] = useState(localStorage.getItem('eighteen'))
    const [nineteen, setNineteen] = useState(localStorage.getItem('nineteen'))
    const [twenty, setTwenty] = useState(localStorage.getItem('twenty'))
    const [twentyone, setTwentyone] = useState(localStorage.getItem('twentyone'))
    const [twentytwo, setTwentytwo] = useState(localStorage.getItem('twentytwo'))
    const [twentythree, setTwentythree] = useState(localStorage.getItem('twentythree'))
    const [twentyfour, setTwentyfour] = useState(localStorage.getItem('twentyfour'))
    const [twentyfive, setTwentyfive] = useState(localStorage.getItem('twentyfive'))
    const [twentysix, setTwentysix] = useState(localStorage.getItem('twentysix'))
    const [twentyseven, setTwentyseven] = useState(localStorage.getItem('twentyseven'))
    const [twentyeight, setTwentyeight] = useState(localStorage.getItem('twentyeight'))
    const [twentynine, setTwentynine] = useState(localStorage.getItem('twentynine'))
    const [thirty, setThirty] = useState(localStorage.getItem('thirty'))
    const [thirtyone, setThirtyone] = useState(localStorage.getItem('thirtyone'))
    const [thirtytwo, setThirtytwo] = useState(localStorage.getItem('thirtytwo'))
    const [thirtythree, setThirtythree] = useState(localStorage.getItem('thirtythree'))
    const [thirtyfour, setThirtyfour] = useState(localStorage.getItem('thirtyfour'))
    const [thirtyfive, setThirtyfive] = useState(localStorage.getItem('thirtyfive'))
    const [thirtysix, setThirtysix] = useState(localStorage.getItem('thirtysix'))
    const [thirtyseven, setThirtyseven] = useState(localStorage.getItem('thirtyseven'))
    const [thirtyeight, setThirtyeight] = useState(localStorage.getItem('thirtyeight'))
    const [thirtynine, setThirtynine] = useState(localStorage.getItem('thirtynine'))
    const [forty, setForty] = useState(localStorage.getItem('forty'))

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const copyFactHandler = (text) => {
        console.log(text)
        let info = text.split(":::")
        console.log(info)
        console.log("Fact -> ", info[0])
        console.log("Question -> ", info[1])
        console.log("Answer -> ", info[2])
        navigator.clipboard.writeText(text);
        setOpenSuccessAlert(true);
        setSuccessMessage(`${text} - has been copied to clipboard.`)
    }

    const mutliplesOfFive = [5, 10]

    const getColorForCopyIcon = (factVal) => {
        try {
            const infoLength = factVal.split("::").length - 1
            if (mutliplesOfFive.includes(infoLength)) {
                return "#29AB87"
            } else if (infoLength > 0) {
                return "#FF7276"
            } else {
                return "grey"
            }
        } catch {
            return "grey"
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box sx={{ flexGrow: 2 }}>
                <Grid align="center" justify="center" spacing={2} sx={{ marginBottom: "100px" }}>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <h1 className="header-top mt-5"> Transient Board </h1>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={one}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setOne(str)
                                localStorage.setItem('one', event.target.value.trim())
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(one)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(one) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={two}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setTwo(str)
                                localStorage.setItem('two', event.target.value)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(two)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(two) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={three}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setThree(str)
                                localStorage.setItem('three', event.target.value)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(three)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(three) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={four}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setFour(str)
                                localStorage.setItem('four', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(four)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(four) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={five}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setFive(str)
                                localStorage.setItem('five', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(five)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(five) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={six}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setSix(str)
                                localStorage.setItem('six', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(six)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(six) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={seven}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setSeven(str)
                                localStorage.setItem('seven', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(seven)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(seven) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={eight}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setEight(str)
                                localStorage.setItem('eight', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(eight)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(eight) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={nine}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setNine(str)
                                localStorage.setItem('nine', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(nine)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(nine) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={ten}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setTen(str)
                                localStorage.setItem('ten', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(ten)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(ten) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={eleven}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setEleven(str)
                                localStorage.setItem('eleven', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(eleven)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(eleven) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={twelve}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setTwelve(str)
                                localStorage.setItem('twelve', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(twelve)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(twelve) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={thirteen}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setThirteen(str)
                                localStorage.setItem('thirteen', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(thirteen)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(thirteen) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={fourteen}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setFourteen(str)
                                localStorage.setItem('fourteen', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(fourteen)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(fourteen) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={fifteen}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setFifteen(str)
                                localStorage.setItem('fifteen', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(fifteen)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(fifteen) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={sixteen}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setSixteen(str)
                                localStorage.setItem('sixteen', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(sixteen)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(sixteen) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={seventeen}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setSeventeen(str)
                                localStorage.setItem('seventeen', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(seventeen)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(seventeen) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={eighteen}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setEighteen(str)
                                localStorage.setItem('eighteen', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(eighteen)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(eighteen) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={nineteen}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setNineteen(str)
                                localStorage.setItem('nineteen', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(nineteen)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(nineteen) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={twenty}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setTwenty(str)
                                localStorage.setItem('twenty', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(twenty)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(twenty) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={twentyone}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setTwentyone(str)
                                localStorage.setItem('twentyone', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(twentyone)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(twentyone) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={twentytwo}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setTwentytwo(str)
                                localStorage.setItem('twentytwo', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(twentytwo)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(twentytwo) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={twentythree}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setTwentythree(str)
                                localStorage.setItem('twentythree', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(twentythree)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(twentythree) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={twentyfour}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setTwentyfour(str)
                                localStorage.setItem('twentyfour', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(twentyfour)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(twentyfour) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={twentyfive}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setTwentyfive(str)
                                localStorage.setItem('twentyfive', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(twentyfive)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(twentyfive) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={twentysix}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setTwentysix(str)
                                localStorage.setItem('twentysix', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(twentysix)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(twentysix) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={twentyseven}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setTwentyseven(str)
                                localStorage.setItem('twentyseven', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(twentyseven)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(twentyseven) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={twentyeight}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setTwentyeight(str)
                                localStorage.setItem('twentyeight', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(twentyeight)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(twentyeight) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={twentynine}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setTwentynine(str)
                                localStorage.setItem('twentynine', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(twentynine)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(twentynine) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={thirty}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setThirty(str)
                                localStorage.setItem('thirty', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(thirty)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(thirty) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={thirtyone}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setThirtyone(str)
                                localStorage.setItem('thirtyone', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(thirtyone)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(thirtyone) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={thirtytwo}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setThirtytwo(str)
                                localStorage.setItem('thirtytwo', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(thirtytwo)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(thirtytwo) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={thirtythree}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setThirtythree(str)
                                localStorage.setItem('thirtythree', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(thirtythree)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(thirtythree) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={thirtyfour}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setThirtyfour(str)
                                localStorage.setItem('thirtyfour', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(thirtyfour)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(thirtyfour) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={thirtyfive}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setThirtyfive(str)
                                localStorage.setItem('thirtyfive', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(thirtyfive)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(thirtyfive) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={thirtysix}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setThirtysix(str)
                                localStorage.setItem('thirtysix', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(thirtysix)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(thirtysix) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={thirtyseven}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setThirtyseven(str)
                                localStorage.setItem('thirtyseven', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(thirtyseven)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(thirtyseven) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={thirtyeight}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setThirtyeight(str)
                                localStorage.setItem('thirtyeight', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(thirtyeight)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(thirtyeight) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={thirtynine}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setThirtynine(str)
                                localStorage.setItem('thirtynine', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(thirtynine)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(thirtynine) }} />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <TextField
                            multiline
                            value={forty}
                            sx={{ width: "60%", margin: "1%", }}
                            onChange={(event, item) => {
                                var str = event.target.value
                                str = str.replace(/ +(?= )/g, '')
                                setForty(str)
                                localStorage.setItem('forty', str)
                            }}
                        />
                        <IconButton aria-label="delete" onClick={() => copyFactHandler(forty)} sx={{ marginX: 1 }}>
                            <Star style={{ fontSize: 40, color: getColorForCopyIcon(forty) }} />
                        </IconButton>
                    </Grid>

                </Grid>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
            </Box>
        </Box>
    )
}

export default TransientBoard;