import {
    Autocomplete,
    Box,
    Button,
    Grid,
    Snackbar,
    Stack,
    TextField
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { subtopicsMap, topicsList } from '../Utils/Topics'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function Others() {
    const [fact, setFact] = useState({ _id: '', question: '', explanation: '' })
    const [apiCalled, setApiCalled] = useState(false)
    const [currentTopic, setCurrentTopic] = useState(topicsList[0].label)
    const [currentSubtopic, setCurrentSubtopic] = useState(
        subtopicsMap['geography'][0].label
    )
    const [currentSubtopicList, setCurrentSubtopicList] = useState(
        subtopicsMap['geography']
    )

    const [successMessage, setSuccessMessage] = useState('Success!!!')
    const [errorMessage, setErrorMessage] = useState('Error!!!')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const getFactsWithoutTopicsAndSubtopics = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(
            'https://api.studencity.in/api/upsc/topics/assign',
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                console.log('Fact data: ', data)
                if (data.status === 200) {
                    setApiCalled(true)
                    setFact(data.data.fact)
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const handleTopicChange = (event, item) => {
        if (item === null) {
            setCurrentTopic(null)
            setCurrentSubtopicList([])
            return
        }

        setCurrentTopic(item.label)
        setCurrentSubtopicList(subtopicsMap[item.value] || [])
        setCurrentSubtopic(subtopicsMap[item.value][0].label)
        console.log(item.label)
    }

    const assignTopicAndSubtopicHandler = () => {
        console.log('Starting assignment...')
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                factId: fact._id,
                topic: currentTopic,
                subtopic: currentSubtopic
            })
        }

        fetch('https://api.studencity.in/api/upsc/topics/assign', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setSuccessMessage(data.data.info)
                    setOpenSuccessAlert(true)
                    setFact({ _id: '', question: '', explanation: '' })
                    getFactsWithoutTopicsAndSubtopics()
                } else {
                    setErrorMessage(data.data.info)
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            getFactsWithoutTopicsAndSubtopics()
        }
    })

    return (
        <>
            {fact.question.length > 0 && (
                <div className='others__container'>
                    <Box sx={{ flexGrow: 2 }} className=''>
                        <h1 className='header-top mt-5'>
                            Add General Knowledge Fact
                        </h1>
                        <h2 className='mt-5'>{fact.question}</h2>
                        <h2 className='mt-5'>{fact.explanation}</h2>
                        <Grid
                            align='center'
                            justify='center'
                            spacing={2}></Grid>
                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <Autocomplete
                                disableportal='true'
                                id='combo-box-demo'
                                options={topicsList}
                                value={currentTopic}
                                sx={{ width: '100%' }}
                                onChange={handleTopicChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Choose topic'
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <Autocomplete
                                disableportal='true'
                                id='combo-box-demo'
                                options={currentSubtopicList}
                                value={currentSubtopic}
                                sx={{ width: '100%' }}
                                onChange={(event, item) => {
                                    if (item === null) return
                                    setCurrentSubtopic(item.label)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Choose sub-topic'
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <Button
                                className='h-12'
                                sx={{ width: '100%' }}
                                onClick={assignTopicAndSubtopicHandler}
                                color='success'
                                variant='contained'>
                                Assign Topic and Subtopic
                            </Button>
                        </Grid>

                        <Stack spacing={2} sx={{ width: '100%' }}>
                            <Snackbar
                                open={openSuccessAlert}
                                autoHideDuration={2000}
                                onClose={closeSuccessAlert}>
                                <Alert
                                    onClose={closeSuccessAlert}
                                    severity='success'
                                    sx={{ width: '100%' }}>
                                    {successMessage}
                                </Alert>
                            </Snackbar>
                        </Stack>

                        <Stack spacing={2} sx={{ width: '100%' }}>
                            <Snackbar
                                open={openErrorAlert}
                                autoHideDuration={2000}
                                onClose={closeErrorAlert}>
                                <Alert
                                    onClose={closeErrorAlert}
                                    severity='error'
                                    sx={{ width: '100%' }}>
                                    {errorMessage}
                                </Alert>
                            </Snackbar>
                        </Stack>
                    </Box>
                </div>
            )}
        </>
    )
}

export default Others
