import './Quiz.css'

import * as React from 'react';
import { useEffect } from 'react';
import { Grid, IconButton, Snackbar, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Upload } from '@mui/icons-material';
import { getLiveEndpoint } from '../../../Utils/url';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function convertMillisecondsToDate(ms) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(ms).toLocaleDateString(undefined, options);
}

const Quiz = () => {

    const [apiCalled, setApiCalled] = useState(false);
    const [blockedDomains, setBlockedDomains] = useState([])

    const [todoTopic, setTodoTopic] = useState('')
    const [completeTodoId, setCompleteTodoId] = useState('')

    const [scheduledPostId, setScheduledPostId] = useState('')
    const [scheduledPostDate, setScheduledPostDate] = useState(0)

    const [editFactText, setEditFactText] = useState('')
    const [editFactId, setEditFactId] = useState('')

    // Modals
    const [modalOpen, setModalOpen] = React.useState(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    // Edit Fact Modal
    const [modalOpenEditFact, setModalOpenEditFact] = React.useState(false);
    const handleModalOpenEditFact = () => setModalOpenEditFact(true);
    const handleModalCloseEditFact = () => setModalOpenEditFact(false);

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const resetAllFields = () => {
        setApiCalled(false)
    }

    const copyFactHandler = (text) => {
        navigator.clipboard.writeText(text);
        setOpenSuccessAlert(true);
        setSuccessMessage(`${text} - has been copied to clipboard.`)
    }

    const getBlockedListOfUser = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch(getLiveEndpoint(true, 'api/upsc/quiz/story/new'), requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    setApiCalled(true);
                    const array = data.data.list
                    setBlockedDomains(array);
                    setSuccessMessage('Quiz questions have been fetched!!')
                    setOpenSuccessAlert(true)
                } else if (data.status === 401) {
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const markQuizAsCompleteHandler = (quizID) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "quizId": quizID })
        };

        fetch(getLiveEndpoint(true, 'api/upsc/quiz/story/uploaded/complete'), requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('Quiz has been completed!!')
                    setOpenSuccessAlert(true)
                    setBlockedDomains((blockedDomains) => {
                        return blockedDomains.filter((product) => product._id !== quizID)
                    })
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    setErrorMessage(data.data.error || 'Error')
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }
    
    useEffect(() => {
        if (!apiCalled) {
            getBlockedListOfUser()
        }
    }, [])

    return (
        <Box sx={{ flexGrow: 2 }}>
            <Grid align="center" justify="center" spacing={2}>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <h1 className="header-top mt-5"> GK Quiz for Stories </h1>
                </Grid>

                {apiCalled === true ?
                    <>
                        {blockedDomains.map((eachDomain) => {
                            return (
                                <Table sx={{ width: "70%", marginTop: 5, marginBottom: 5 }} aria-label="customized table" key={eachDomain._id}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>
                                                <h1 className="text-xl font-medium"><IconButton aria-label="delete" onClick={() => markQuizAsCompleteHandler(eachDomain._id)} >
                                                    <Upload style={{ fontSize: 20 }} className='completeQuiz' />
                                                </IconButton> {eachDomain.question} </h1>

                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {eachDomain.options.map((option) => {
                                            if (eachDomain.answerId === option.id) {
                                                return (
                                                    <StyledTableRow key={eachDomain._id}>
                                                        <StyledTableCell component="th" scope="row">
                                                            <h1 className="text-xl text-emerald-600 font-medium"> {option.value} </h1>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            } else {
                                                return (
                                                    <StyledTableRow key={eachDomain._id}>
                                                        <StyledTableCell component="th" scope="row">
                                                            <h1 className="text-xl font-medium"> {option.value} </h1>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            )
                        })}
                    </> : ''}
            </Grid>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </Box>
    )
}

export default Quiz;