import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import MuiAlert from '@mui/material/Alert'
import { Autocomplete, IconButton, Input, TextField } from '@mui/material'
import { AutoFixHigh } from '@mui/icons-material'
import { localItems } from '../../Helper/localStorage'
import { subtopicsMap, topicsList } from '../../Utils/Topics'
import DrawerNav from '../Navigation/DrawerNav';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const AddFact = () => {
    const [fact, setFact] = useState('')
    const [question, setQuestion] = useState('')
    const [answer, setAnswer] = useState('')
    const [correctOption, setCorrectOption] = useState('A')
    const [optionA, setOptionA] = useState('')
    const [optionB, setOptionB] = useState('')
    const [optionC, setOptionC] = useState('')
    const [optionD, setOptionD] = useState('')

    const [questionSecond, setQuestionSecond] = useState('')
    const [answerSecond, setAnswerSecond] = useState('')
    const [correctOptionSecond, setCorrectOptionSecond] = useState('A')
    const [optionASecond, setOptionASecond] = useState('')
    const [optionBSecond, setOptionBSecond] = useState('')
    const [optionCSecond, setOptionCSecond] = useState('')
    const [optionDSecond, setOptionDSecond] = useState('')

    const [currentTopic, setCurrentTopic] = useState(topicsList[0].label)
    const [currentSubtopic, setCurrentSubtopic] = useState(subtopicsMap['geography'][0].label)

    const [localFactIndex, setLocalFactIndex] = useState('')

    const [successMessage, setSuccessMessage] = useState('Success!!!')
    const [errorMessage, setErrorMessage] = useState('Error!!!')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const clearInputFields = () => {
        setFact('')
        setQuestion('')
        setAnswer('')
        setOptionA('')
        setOptionB('')
        setOptionC('')
        setOptionD('')
        setCorrectOption('A')
        setQuestionSecond('')
        setAnswerSecond('')
        setOptionASecond('')
        setOptionBSecond('')
        setOptionCSecond('')
        setOptionDSecond('')
        setCorrectOptionSecond('A')
    }

    const addNewFactHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                fact: fact,
                topic: currentTopic,
                subtopic: currentSubtopic,
                question: question,
                answer: answer,
                optionA: optionA,
                optionB: optionB,
                optionC: optionC,
                optionD: optionD,
                correctOption: correctOption
            })
        }

        fetch('https://api.studencity.in/api/upsc/generalfacts', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('Fact has been uploaded.')
                    setOpenSuccessAlert(true)
                    addSecondQuestionToRecords()
                } else {
                    setErrorMessage(data.data.error)
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    const addSecondQuestionToRecords = () => {
        if (!questionSecond) {
            clearInputFields()
            eraseLocalFact(localFactIndex)
            return
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                fact: fact,
                topic: currentTopic,
                subtopic: currentSubtopic,
                question: questionSecond,
                answer: answerSecond,
                optionA: optionASecond,
                optionB: optionBSecond,
                optionC: optionCSecond,
                optionD: optionDSecond,
                correctOption: correctOptionSecond
            })
        }

        fetch('https://api.studencity.in/api/upsc/generalfacts/quiz', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('Second question has been uploaded')
                    setOpenSuccessAlert(true)
                    clearInputFields()
                    eraseLocalFact(localFactIndex)
                } else {
                    setErrorMessage(data.data.error)
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    const randomIntFromInterval = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const getRandomOption = () => {
        const randomNum = randomIntFromInterval(1, 4)
        if (randomNum === 1) {
            return 'A'
        } else if (randomNum === 2) {
            return 'B'
        } else if (randomNum === 3) {
            return 'C'
        } else {
            return 'D'
        }
    }

    const mutliplesOfFive = [5, 10]

    const getFirstValidFact = () => {
        let validIndex = 'infinity'
        let validIndexArray = []

        try {
            localItems.forEach((eachFact, i) => {
                try {
                    const infoLength = localStorage.getItem(eachFact).split('::').length - 1
                    if (mutliplesOfFive.includes(infoLength)) {
                        validIndex = eachFact
                        validIndexArray.push(eachFact)
                    }
                } catch { }
            })
            return validIndex === 'infinity' ? false : validIndexArray[0]
        } catch {
            return validIndex === 'infinity' ? false : validIndexArray[0]
        }
    }

    const eraseLocalFact = (index) => {
        localStorage.removeItem(index)
    }

    const autoFillFact = () => {
        const validFactIndex = getFirstValidFact()
        if (!validFactIndex) {
            setErrorMessage('No valid fact available')
            setOpenErrorAlert(true)
            return
        }

        setSuccessMessage(`Fact with index ${validFactIndex} found`)
        setOpenSuccessAlert(true)
        setLocalFactIndex(validFactIndex)

        const fact = localStorage.getItem(validFactIndex)
        const info = fact.split('::')
        setFact(info[0])
        setQuestion(info[1])
        setAnswer(info[2])
        setQuestionSecond(info[6])
        setAnswerSecond(info[7])

        const randomOption = getRandomOption()
        if (randomOption === 'A') {
            setOptionA(info[2])
            setOptionB(info[3])
            setOptionC(info[4])
            setOptionD(info[5])
            setCorrectOption(randomOption)
            setOptionASecond(info[7])
            setOptionBSecond(info[10])
            setOptionCSecond(info[9])
            setOptionDSecond(info[8])
            setCorrectOptionSecond(randomOption)
        } else if (randomOption === 'B') {
            setOptionA(info[3])
            setOptionB(info[2])
            setOptionC(info[4])
            setOptionD(info[5])
            setCorrectOption(randomOption)
            setOptionASecond(info[8])
            setOptionBSecond(info[7])
            setOptionCSecond(info[10])
            setOptionDSecond(info[9])
            setCorrectOptionSecond(randomOption)
        } else if (randomOption === 'C') {
            setOptionA(info[4])
            setOptionB(info[3])
            setOptionC(info[2])
            setOptionD(info[5])
            setCorrectOption(randomOption)
            setOptionASecond(info[9])
            setOptionBSecond(info[8])
            setOptionCSecond(info[7])
            setOptionDSecond(info[10])
            setCorrectOptionSecond(randomOption)
        } else if (randomOption === 'D') {
            setOptionA(info[5])
            setOptionB(info[3])
            setOptionC(info[4])
            setOptionD(info[2])
            setCorrectOption(randomOption)
            setOptionASecond(info[8])
            setOptionBSecond(info[9])
            setOptionCSecond(info[10])
            setOptionDSecond(info[7])
            setCorrectOptionSecond(randomOption)
        }
    }

    const [currentSubtopicList, setCurrentSubtopicList] = useState(subtopicsMap['geography'])

    const handleTopicChange = (event, item) => {
        if (item === null) {
            setCurrentTopic(null)
            setCurrentSubtopicList([])
            return
        }

        setCurrentTopic(item.label)
        setCurrentSubtopicList(subtopicsMap[item.value] || [])
        setCurrentSubtopic(subtopicsMap[item.value][0].label)
        console.log(item.label)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box sx={{ flexGrow: 2 }} className='addfact__container'>
                <h1 className='header-top mt-5'>Add General Knowledge Fact</h1>
                <Grid align='center' justify='center' spacing={2}>
                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Input
                            disablePortal
                            placeholder='Enter fact'
                            id='combo-box-demo'
                            multiline
                            value={fact}
                            maxRows={5}
                            sx={{ width: '80%' }}
                            onChange={(event, item) => {
                                setFact(event.target.value)
                            }}
                        />
                        <IconButton aria-label='delete' onClick={() => autoFillFact()} sx={{ marginX: 1 }}>
                            <AutoFixHigh style={{ fontSize: 40, color: '#2874A6' }} />
                        </IconButton>
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Autocomplete disableportal='true' id='combo-box-demo' options={topicsList} value={currentTopic} sx={{ width: '84%' }} onChange={handleTopicChange} renderInput={(params) => <TextField {...params} label='Choose topic' />} />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Autocomplete
                            disableportal='true'
                            id='combo-box-demo'
                            options={currentSubtopicList}
                            value={currentSubtopic}
                            sx={{ width: '84%' }}
                            onChange={(event, item) => {
                                if (item === null) return
                                setCurrentSubtopic(item.label)
                            }}
                            renderInput={(params) => <TextField {...params} label='Choose sub-topic' />}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Input
                            disablePortal
                            placeholder='Enter question...'
                            id='combo-box-demo'
                            multiline
                            value={question}
                            maxRows={5}
                            sx={{ width: '84%' }}
                            onChange={(event, item) => {
                                setQuestion(event.target.value)
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Input
                            disablePortal
                            placeholder='Enter answer...'
                            id='combo-box-demo'
                            multiline
                            value={answer}
                            maxRows={2}
                            sx={{ width: '84%' }}
                            onChange={(event, item) => {
                                setAnswer(event.target.value)
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Input
                            disablePortal
                            placeholder='Option A'
                            id='combo-box-demo'
                            multiline
                            value={optionA}
                            maxRows={2}
                            sx={{ width: '20%', marginRight: '20px' }}
                            onChange={(event, item) => {
                                setOptionA(event.target.value)
                            }}
                        />
                        <Input
                            disablePortal
                            placeholder='Option B'
                            id='combo-box-demo'
                            multiline
                            value={optionB}
                            maxRows={2}
                            sx={{ width: '20%', marginRight: '20px' }}
                            onChange={(event, item) => {
                                setOptionB(event.target.value)
                            }}
                        />
                        <Input
                            disablePortal
                            placeholder='Option C'
                            id='combo-box-demo'
                            multiline
                            value={optionC}
                            maxRows={2}
                            sx={{ width: '20%', marginRight: '20px' }}
                            onChange={(event, item) => {
                                setOptionC(event.target.value)
                            }}
                        />
                        <Input
                            disableportal='true'
                            placeholder='Option D'
                            id='combo-box-demo'
                            multiline
                            value={optionD}
                            maxRows={2}
                            sx={{ width: '20%', marginLeft: '20px' }}
                            onChange={(event, item) => {
                                setOptionD(event.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Autocomplete
                            disableportal='true'
                            id='combo-box-demo'
                            options={optionsList}
                            value={correctOption}
                            sx={{ width: '84%' }}
                            onChange={(event, item) => {
                                if (item === null) return
                                setCorrectOption(item.label)
                            }}
                            renderInput={(params) => <TextField {...params} label='Choose correct option' />}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Input
                            disablePortal
                            placeholder='Enter question...'
                            id='combo-box-demo'
                            multiline
                            value={questionSecond}
                            maxRows={5}
                            sx={{ width: '84%' }}
                            onChange={(event, item) => {
                                setQuestionSecond(event.target.value)
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Input
                            disablePortal
                            placeholder='Enter answer...'
                            id='combo-box-demo'
                            multiline
                            value={answerSecond}
                            maxRows={2}
                            sx={{ width: '84%' }}
                            onChange={(event, item) => {
                                setAnswerSecond(event.target.value)
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Input
                            disablePortal
                            placeholder='Option A'
                            id='combo-box-demo'
                            multiline
                            value={optionASecond}
                            maxRows={2}
                            sx={{ width: '20%', marginRight: '20px' }}
                            onChange={(event, item) => {
                                setOptionASecond(event.target.value)
                            }}
                        />
                        <Input
                            disablePortal
                            placeholder='Option B'
                            id='combo-box-demo'
                            multiline
                            value={optionBSecond}
                            maxRows={2}
                            sx={{ width: '20%', marginRight: '20px' }}
                            onChange={(event, item) => {
                                setOptionBSecond(event.target.value)
                            }}
                        />
                        <Input
                            disablePortal
                            placeholder='Option C'
                            id='combo-box-demo'
                            multiline
                            value={optionCSecond}
                            maxRows={2}
                            sx={{ width: '20%', marginRight: '20px' }}
                            onChange={(event, item) => {
                                setOptionCSecond(event.target.value)
                            }}
                        />
                        <Input
                            disableportal='true'
                            placeholder='Option D'
                            id='combo-box-demo'
                            multiline
                            value={optionDSecond}
                            maxRows={2}
                            sx={{ width: '20%', marginLeft: '20px' }}
                            onChange={(event, item) => {
                                setOptionDSecond(event.target.value)
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Autocomplete
                            disableportal='true'
                            id='combo-box-demo'
                            options={optionsList}
                            value={correctOptionSecond}
                            sx={{ width: '84%' }}
                            onChange={(event, item) => {
                                if (item === null) return
                                setCorrectOptionSecond(item.label)
                            }}
                            renderInput={(params) => <TextField {...params} label='Choose correct option' />}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Button className='h-12' sx={{ width: '84%' }} onClick={addNewFactHandler} color='success' variant='contained'>
                            Add new fact
                        </Button>
                    </Grid>
                </Grid>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
            </Box>
        </Box>
    )
}

const optionsList = [
    {
        label: 'A'
    },
    {
        label: 'B'
    },
    {
        label: 'C'
    },
    {
        label: 'D'
    }
]

export default AddFact
