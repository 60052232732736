const localPort = '4000'
const localUrl = 'http://localhost:' + localPort + '/'
const liveUrl = 'https://api.studencity.in/'

exports.getLiveEndpoint = (liveFlag, endpoint) => {
    if (liveFlag) {
        return liveUrl + endpoint
    } else {
        return localUrl + endpoint
    }
}