import './Links.css'

import * as React from 'react';
import { useEffect } from 'react';
import { Button, Grid, IconButton, Input, Snackbar, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ArrowCircleRight, Delete, DoneAll, SlideshowOutlined } from '@mui/icons-material';
import { showTimeInAgoFormat } from '../../../Utils/time';
import DrawerNav from '../../Navigation/DrawerNav';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function convertMillisecondsToDate(ms) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(ms).toLocaleDateString(undefined, options);
}

const importantLinks = [
    {
        name: 'Dhristi IAS',
        url: 'https://www.drishtiias.com/current-affairs-news-analysis-editorials',
        lastUsed: new Date().getTime()
    },
    {
        name: 'GK Today Current Affairs',
        url: 'https://www.gktoday.in/',
        lastUsed: new Date().getTime()
    },
    {
        name: 'Byju Current Affairs',
        url: 'https://byjusexamprep.com/current-affairs',
        lastUsed: new Date().getTime()
    },
    {
        name: 'Instagram Page - Current Affairs GK Page',
        url: 'https://www.instagram.com/currentaffair_gk/',
        lastUsed: new Date().getTime()
    },
    {
        name: 'Google Slides - Year Facts',
        url: 'https://docs.google.com/presentation/d/1YCKRpGXNqgwG-8fdxx4QTUy6g2W4SmFhJ7kQP4SU50g/edit#slide=id.p',
        lastUsed: new Date().getTime()
    },
    {
        name: 'Vision IAS - Current Affairs',
        url: 'http://visionias.in/resources/daily_current_affairs.php?type=1',
        lastUsed: new Date().getTime()
    },
    {
        name: 'Arihant General Knowledge Chapterwise',
        url: 'http://visionias.in/resources/daily_current_affairs.php?type=1',
        lastUsed: new Date().getTime()
    }
]

const Links = () => {

    const [apiCalled, setApiCalled] = useState(false);
    const [blockedDomains, setBlockedDomains] = useState([])

    const [linkName, setLinkName] = useState('')
    const [linkUrl, setLinkUrl] = useState('')

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const resetAllFields = () => {
        setApiCalled(false)
    }

    const openURLInNewPage = (text) => {
        window.open(text, '_blank', 'noreferrer');
    }

    const addNewTodoButtonHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "name": linkName, "url": linkUrl })
        };

        fetch('https://api.studencity.in/api/upsc/dashboard/links', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('Link has been added!!')
                    setOpenSuccessAlert(true)
                    getBlockedListOfUser()
                    setLinkName('')
                    setLinkUrl('')
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                    setErrorMessage(data.data.info)
                }
            })
            .catch((error) => {
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    const getBlockedListOfUser = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch('https://api.studencity.in/api/upsc/dashboard/links', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    setApiCalled(true);
                    const array = data.data.links
                    setBlockedDomains(array);
                } else if (data.status === 401) {
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const updateLinkTimeHandler = (linkID) => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "linkId": linkID })
        };

        fetch('https://api.studencity.in/api/upsc/dashboard/links', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('Link has been updated!!')
                    setOpenSuccessAlert(true)
                    getBlockedListOfUser()
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    setErrorMessage(data.data.error || 'Error')
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })
    }

    const deleteLinkHandler = (linkID) => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "linkId": linkID })
        };

        fetch('https://api.studencity.in/api/upsc/dashboard/links', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200 || data.status === 201) {
                    setSuccessMessage('Link has been deleted!!')
                    setOpenSuccessAlert(true)
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                } else if (data.status === 403) {
                } else {
                    setErrorMessage(data.data.error || 'Error')
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setErrorMessage(error)
                setOpenErrorAlert(true)
            })

        setBlockedDomains((blockedDomains) => {
            return blockedDomains.filter((product) => product._id !== linkID)
        })
    }


    const capitalizeTag = (tag) => {
        if (tag === 'generalKnowledge') {
            return 'General Knowledge'
        } else if (tag === 'national') {
            return 'National'
        }
        return tag
    }

    useEffect(() => {
        if (!apiCalled) {
            getBlockedListOfUser()
        }
    }, [])

    const showTags = (tags) => {
        let tagString = ""
        tags.forEach((tag, i) => {
            if (i > 0) {
                tagString += ", "
                tagString += capitalizeTag(tag)
            } else {
                tagString += capitalizeTag(tag)
            }
        })
        return tagString
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box sx={{ flexGrow: 2 }}>
                <Grid align="center" justify="center" spacing={2}>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <h1 className="header-top mt-5"> Important Links </h1>
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className="pt-5" sx={{ marginX: 10 }}>
                        <IconButton aria-label="delete" onClick={() => openURLInNewPage('add')} sx={{ marginX: 1 }}>
                            <SlideshowOutlined style={{ fontSize: 50, color: "#f1c232" }} />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={() => openURLInNewPage('add')} sx={{ marginX: 1 }}>
                            <SlideshowOutlined style={{ fontSize: 50, color: "#f1c232" }} />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={() => openURLInNewPage('add')} sx={{ marginX: 1 }}>
                            <SlideshowOutlined style={{ fontSize: 50, color: "#f1c232" }} />
                        </IconButton>
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Input
                            disablePortal
                            placeholder="Name"
                            id="combo-box-demo"
                            multiline
                            value={linkName}
                            maxRows={2}
                            sx={{ width: "300px", marginRight: "50px" }}
                            onChange={(event, item) => {
                                setLinkName(event.target.value)
                            }}
                        />

                        <Input
                            disablePortal
                            placeholder="URL"
                            id="combo-box-demo"
                            multiline
                            value={linkUrl}
                            maxRows={2}
                            sx={{ width: "300px", marginRight: "50px" }}
                            onChange={(event, item) => {
                                setLinkUrl(event.target.value)
                            }}
                        />

                        <Button
                            className="h-12"
                            sx={{ width: "200px" }}
                            onClick={addNewTodoButtonHandler}
                            variant="contained">
                            Add new Link
                        </Button>
                    </Grid>

                    <Table sx={{ width: "80%", marginTop: 10, marginBottom: 30 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Topics</StyledTableCell>
                                <StyledTableCell>Last Used</StyledTableCell>
                                <StyledTableCell>URL</StyledTableCell>
                                <StyledTableCell>Used</StyledTableCell>
                                <StyledTableCell>Delete</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {blockedDomains.map((eachDomain) => {
                            return (
                                <TableBody>
                                    <StyledTableRow key={eachDomain._id}>
                                        <StyledTableCell component="th" scope="row">
                                            {eachDomain.name}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {showTimeInAgoFormat(eachDomain.lastUsed)}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <IconButton aria-label="delete" onClick={() => openURLInNewPage(eachDomain.url)}>
                                                <ArrowCircleRight style={{ fontSize: 30, color: "#0F52BA" }} />
                                            </IconButton>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <IconButton aria-label="delete" onClick={() => updateLinkTimeHandler(eachDomain._id)}>
                                                <DoneAll style={{ fontSize: 30, color: "#0F52BA" }} />
                                            </IconButton>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <IconButton aria-label="delete" onClick={() => deleteLinkHandler(eachDomain._id)}>
                                                <Delete style={{ fontSize: 30, color: "crimson" }} />
                                            </IconButton>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            )
                        })}
                    </Table>
                </Grid>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
            </Box>
        </Box>

    )
}

export default Links;