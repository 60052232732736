import './Progress.css'

import * as React from 'react'
import { useEffect } from 'react'
import { Grid, IconButton, Snackbar, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { BarChart } from '@mui/x-charts/BarChart'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { FiberManualRecord } from '@mui/icons-material'
import DrawerNav from '../../Navigation/DrawerNav'

const modalSyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'white',
    border: '2px solid gray',
    boxShadow: 24,
    p: 4
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function convertMillisecondsToDate(ms) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(ms).toLocaleDateString(undefined, options)
}

const Progress = () => {
    const [apiCalled, setApiCalled] = useState(false)
    const [blockedDomains, setBlockedDomains] = useState([])

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetAllFields = () => {
        setApiCalled(false)
    }

    const [monthArray, setMonthArray] = useState([])
    const [quizCountArray, setQuizCountArray] = useState([])
    let temp = [],
        temp2 = []

    const getDashboardQuizStats = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch('https://api.studencity.in/api/dashboard/stats/v1/quiz', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    let statsData = data.data.stats
                    statsData = statsData.reverse()
                    statsData.forEach((eachStat) => {
                        temp.push(`${getMonthNameFromNumber(eachStat.month)}, ${eachStat.year} - ${eachStat.total}`)
                        temp2.push(eachStat.total)
                    })
                    setMonthArray(temp)
                    setQuizCountArray(temp2)
                    setApiCalled(true)
                    getBlockedListOfUser()
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const getBlockedListOfUser = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch('https://api.studencity.in/api/upsc/dashboard/calendar/progress', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 200) {
                    setApiCalled(true)
                    const array = data.data.calendar
                    setBlockedDomains(array)
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const capitalizeTag = (tag) => {
        if (tag === 'generalKnowledge') {
            return 'General Knowledge'
        } else if (tag === 'national') {
            return 'National'
        }
        return tag
    }

    useEffect(() => {
        if (!apiCalled) {
            getDashboardQuizStats()
        }
    }, [])

    const showTags = (tags) => {
        let tagString = ''
        tags.forEach((tag, i) => {
            if (i > 0) {
                tagString += ', '
                tagString += capitalizeTag(tag)
            } else {
                tagString += capitalizeTag(tag)
            }
        })
        return tagString
    }

    const getMonthNameFromNumber = (monthIndex) => {
        let monthsArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
        return monthsArray[monthIndex - 1]
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box sx={{ flexGrow: 2 }}>
                    <Grid align='center' justify='center' spacing={2}>
                        <div className='ticketfeedback__container'>
                            {monthArray.length > 0 && (
                                <BarChart
                                    xAxis={[
                                        {
                                            id: 'barCategories',
                                            data: monthArray,
                                            scaleType: 'band'
                                        }
                                    ]}
                                    series={[{ data: quizCountArray, color: 'dodgerblue' }]}
                                    height={500}
                                />
                            )}
                        </div>

                        <Grid item lg={4} md={6} sm={12} className='pt-5'>
                            <h1 className='header-top mt-5'>Progress</h1>
                        </Grid>

                        {apiCalled === true ? (
                            <>
                                <Table sx={{ width: '90%', marginTop: 5, marginBottom: 30 }} aria-label='customized table'>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell width={'10%'}>Date</StyledTableCell>
                                            <StyledTableCell>Count</StyledTableCell>
                                            <StyledTableCell>Posts Created</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    {blockedDomains.map((eachDomain) => {
                                        return (
                                            <TableBody>
                                                <StyledTableRow key={eachDomain._id}>
                                                    <StyledTableCell component='th' scope='row'>
                                                        {`${eachDomain.day} ${getMonthNameFromNumber(eachDomain.month)}, 2023`}
                                                    </StyledTableCell>
                                                    <StyledTableCell component='th' scope='row'>
                                                        {eachDomain.count}
                                                    </StyledTableCell>
                                                    <StyledTableCell component='th' scope='row'>
                                                        {[...Array(eachDomain.count)].map((item, i) => {
                                                            return (
                                                                <IconButton aria-label='delete' sx={{}}>
                                                                    <FiberManualRecord style={{ fontSize: 30, color: '#009E60' }} />
                                                                </IconButton>
                                                            )
                                                        })}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        )
                                    })}
                                </Table>
                            </>
                        ) : (
                            ''
                        )}
                    </Grid>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                            <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                {successMessage}
                            </Alert>
                        </Snackbar>
                    </Stack>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                            <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                {errorMessage}
                            </Alert>
                        </Snackbar>
                    </Stack>
                </Box>

            </Box>

        </>

    )
}

export default Progress
