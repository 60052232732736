import React, { useEffect, useState } from 'react'
import { useTable } from 'react-table'

import { BarChart } from '@mui/x-charts/BarChart'

const getMonthNameFromNumber = (monthIndex) => {
    let monthsArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    return monthsArray[monthIndex - 1]
}

const FactTable = (props) => {
    const columns = React.useMemo(
        () => [
            { Header: 'Topic', accessor: 'fact' },
            { Header: 'Subtopic', accessor: 'subtopic' },
            { Header: 'Count', accessor: 'count' }
        ],
        []
    )

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: props.data
    })

    return (
        <div className='p-10'>
            <div className='ticketfeedback__container mb-20'>
                {props.monthArray.length > 0 && (
                    <BarChart
                        xAxis={[
                            {
                                id: 'barCategories',
                                data: props.monthArray,
                                scaleType: 'band'
                            }
                        ]}
                        series={[{ data: props.quizCountArray, color: 'dodgerblue' }]}
                        height={500}
                    />
                )}
            </div>
            <table {...getTableProps()} style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    style={{
                                        border: '1px solid black',
                                        padding: '8px',
                                        fontSize: '30px'
                                    }}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} key={row.id}>
                                {row.cells.map((cell, index) => (
                                    <td
                                        {...cell.getCellProps()}
                                        style={{
                                            border: '1px solid black',
                                            padding: '8px',
                                            paddingLeft: '20px',
                                            color: index === 2 && cell.value < 10 ? 'black' : index === 2 && cell.value < 20 ? 'purple' : index === 2 && cell.value < 30 ? 'orange' : index === 2 && cell.value < 40 ? 'green' : 'black',
                                            fontWeight: index === 2 ? 'bold' : 'normal',
                                            fontSize: index === 2 ? '30px' : '30px'
                                        }}>
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

function TopicCount() {
    const [apiCalled, setApiCalled] = useState(false)
    const [factData, setFactData] = useState([])
    const [monthArray, setMonthArray] = useState([])
    const [quizCountArray, setQuizCountArray] = useState([])

    const getTopicCount = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch('https://api.studencity.in/api/upsc/analysis/assigned', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Fact data: ', data)
                if (data.status === 200) {
                    const flatDataVal = data.data.fact.flatMap((fact) => fact.list.map((item) => ({ fact: fact._id, ...item })))
                    setFactData(flatDataVal)
                    console.log('Flat Data : ', flatDataVal)
                    let temp = [],
                        temp2 = []
                    flatDataVal.forEach((eachStat) => {
                        temp.push(`${eachStat.fact} - ${eachStat.subtopic}`)
                        temp2.push(eachStat.count)
                    })
                    setMonthArray(temp)
                    setQuizCountArray(temp2)
                    setApiCalled(true)
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            getTopicCount()
        }
    })
    return (
        <div>
            <h1 className='mt-10'>Topics and Subtopics</h1>
            {factData.length > 0 && <FactTable data={factData} monthArray={monthArray} quizCountArray={quizCountArray} />}
        </div>
    )
}

export default TopicCount
