import './GetYearFacts.css'

import * as React from 'react';
import { useEffect } from 'react';
import { Grid, Snackbar, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getLiveEndpoint } from '../../../Utils/url';
import DrawerNav from '../../Navigation/DrawerNav';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GetYearFacts = () => {

    const [apiCalled, setApiCalled] = useState(false);
    const [blockedDomains, setBlockedDomains] = useState([])
    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const resetAllFields = () => {
        setApiCalled(false)
    }

    const getBlockedListOfUser = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "startYear": 1900, "endYear": 1950 })
        };

        fetch(getLiveEndpoint(true, 'api/upsc/facts/year'), requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    setApiCalled(true);
                    const array = data.data.facts
                    setBlockedDomains(array);
                } else if (data.status === 401) {
                } else if (data.status === 403) {
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            getBlockedListOfUser()
        }
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box sx={{ flexGrow: 2 }}>
                <Grid align="center" justify="center" spacing={2}>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <h1 className="header-top mt-5"> Year Facts</h1>
                    </Grid>

                    {apiCalled === true ?
                        <>

                            {blockedDomains.map((eachDomain) => {
                                return (
                                    <Table sx={{ width: "55%", marginTop: 5, marginBottom: 30 }} aria-label="customized table" key={eachDomain._id}>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>{eachDomain._id}</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <StyledTableRow key={eachDomain._id}>
                                                {
                                                    eachDomain.list.map((eachEvent) => {
                                                        return (
                                                            <StyledTableCell component="th" scope="row">
                                                                {eachEvent}
                                                            </StyledTableCell>
                                                        )
                                                    })
                                                }
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                )
                            })}
                        </> : ''}
                </Grid>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                            {successMessage}
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Stack>
            </Box>
        </Box>
    )
}

export default GetYearFacts;