exports.localItems = [
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
    'twenty',
    'twentyone',
    'twentytwo',
    'twentythree',
    'twentyfour',
    'twentyfive',
    'twentysix',
    'twentyseven',
    'twentyeight',
    'twentynine',
    'thirty'
]